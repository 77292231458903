import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { QUERYSTRING } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { evictCacheById } from "@/common/utils/cacheUtils";
import {
  BuyoutStatus,
  BuyoutsBuyoutFieldsFragment,
  QueryBuyoutsFilter,
  namedOperations,
  useArchiveBuyoutMutation,
  useBuyoutsQuery,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { defaultIfUndefined } from "../../invoices/pages/scanned-invoices/providers/defaultIfUndefined";

export enum BuyoutsFilterSelection {
  Draft = "DRAFT",
  Requested = "REQUESTED",
  Active = "ACTIVE",
  Depleted = "DEPLETED",
  Cancelled = "CANCELLED",
}

type ProviderContextType = {
  buyouts: BuyoutsBuyoutFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  filter?: QueryBuyoutsFilter;
  setFilter: (filter: QueryBuyoutsFilter) => void;
  archiveBuyout: (id: string) => Promise<boolean>;
  isFiltered: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  buyouts: [],
  loading: false,
  error: false,
  totalCount: 0,
  filter: undefined,
  setFilter: NoFunction,
  archiveBuyout: () => Promise.resolve(false),
  isFiltered: false,
});

const queryParamToFilter = (
  queryParams: URLSearchParams,
  defaultValue?: QueryBuyoutsFilter | null,
): QueryBuyoutsFilter => {
  const projectId = queryParams.get(QUERYSTRING.PROJECT_ID);
  const statuses = queryParams.get(QUERYSTRING.BUYOUT_FILTER_STATUSES);
  const closedProjects = queryParams.get(QUERYSTRING.CLOSED_PROJECTS)
    ? queryParams.get(QUERYSTRING.CLOSED_PROJECTS) === "true"
    : defaultIfUndefined(defaultValue?.closedProjects, false);
  const deleted = queryParams.get(QUERYSTRING.ARCHIVED)
    ? queryParams.get(QUERYSTRING.ARCHIVED) === "true"
    : false;
  return {
    projectIDs: projectId ? projectId.split(",") : undefined,
    deleted,
    statuses: statuses ? (statuses.split(",") as BuyoutStatus[]) : undefined,
    closedProjects,
  };
};

export const BuyoutsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { setError } = useGlobalError();
  const { queryParams } = useQueryParams();

  const [filter, setFilter] = useState<QueryBuyoutsFilter>(
    queryParamToFilter(queryParams),
  );

  const { paginationArgs, setPageInfo, setPage, previousPage, page } =
    usePagination();
  const {
    data: buyoutsData,
    loading,
    error,
  } = useBuyoutsQuery({
    variables: {
      ...paginationArgs,
      filter,
    },
  });
  const [archiveBuyoutMutation] = useArchiveBuyoutMutation({
    update: (cache, { data: archiveBuyout }) =>
      evictCacheById(
        cache,
        namedOperations.Query.Buyout,
        archiveBuyout?.archiveBuyout.id,
      ),
  });

  useErrorEffect(error);

  useEffect(() => {
    if (buyoutsData?.buyouts?.pageInfo) {
      setPageInfo(buyoutsData.buyouts.pageInfo);
    }
  }, [buyoutsData, setPageInfo]);

  useEffect(() => {
    setFilter(queryParamToFilter(queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilterAndUpdateQueryString = (filter: QueryBuyoutsFilter) => {
    setFilter(filter);
    setPage({
      page: 0,
      queryParams: {
        [QUERYSTRING.BUYOUT_FILTER_STATUSES]: filter?.statuses,
        [QUERYSTRING.PROJECT_ID]: filter?.projectIDs?.join(","),
        [QUERYSTRING.ARCHIVED]: filter?.deleted,
        [QUERYSTRING.CLOSED_PROJECTS]: filter?.closedProjects,
      },
    });
  };

  const archiveBuyout = async (id: string) => {
    try {
      const { data, errors } = await archiveBuyoutMutation({
        variables: { id },
        onCompleted: () => {
          if (page !== 0 && buyoutsData?.buyouts.edges.length === 1) {
            previousPage();
          }
        },
      });
      setError(errors);
      return !!data?.archiveBuyout;
    } catch (error) {
      setError(error);
    }
    return false;
  };

  return (
    <ProviderContext.Provider
      value={{
        buyouts: buyoutsData?.buyouts?.edges?.map((edge) => edge.node) || [],
        isFiltered: !!filter.projectIDs || !!filter.statuses?.length,
        loading,
        error: !!error,
        totalCount: buyoutsData?.buyouts?.totalCount || 0,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        archiveBuyout,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useBuyouts = (): ProviderContextType =>
  useContext(ProviderContext);
