import { If } from "@/common/components/if/If";
import {
  ItemizedNonItemizedToggle,
  ViewType,
} from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceCreateRelease } from "../../../../providers/InvoiceCreateReleaseProvider";
import { InvoiceSelectModeHeader } from "../invoice-select-mode-header/InvoiceSelectModeHeader";
import { InvoiceCreateReleaseHeader } from "./InvoiceCreateReleaseHeader";
import { InvoiceCreateReleaseItemizedView } from "./InvoiceCreateReleaseItemizedView";
import { InvoiceCreateReleaseNonItemizedView } from "./InvoiceCreateReleaseNonItemizedView";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const HeaderGroup = tw.div`flex w-full place-items-center gap-1 mb-2`;
const ViewToggleContainer = tw.div`flex flex-1 justify-end`;

export const InvoiceCreateRelease: FC = () => {
  const { itemized, setItemized } = useInvoiceCreateRelease();

  return (
    <Container>
      <HeaderGroup>
        <InvoiceSelectModeHeader />
        <ViewToggleContainer>
          <ItemizedNonItemizedToggle
            handleChange={(view) => setItemized(view === ViewType.Itemized)}
            isItemized={itemized}
          />
        </ViewToggleContainer>
      </HeaderGroup>
      <InvoiceCreateReleaseHeader />
      <If isTrue={itemized}>
        <InvoiceCreateReleaseItemizedView />
      </If>
      <If isTrue={!itemized}>
        <InvoiceCreateReleaseNonItemizedView />
      </If>
    </Container>
  );
};
