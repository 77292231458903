import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { InvoiceItemFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useAddItemsModal } from "./useAddItemsModal";

export const useAddInvoiceItems = () => {
  const { handsonInstance } = useColumnMapper();
  const { invoice } = useInvoiceVerification();
  const { findMaterialByName, appendRows, rowIsEmpty } = useTableHelpers();
  const { showModal } = useAddItemsModal();
  const { formatCostCode } = useCostCodes();
  const { calcExtPrice } = usePriceCalculation();

  const getMissingItems = useCallback(() => {
    return (
      invoice?.items.filter(
        (item) =>
          !invoice?.releaseItemHints.some(
            (hint) => hint.invoiceItem?.id === item.id,
          ),
      ) || []
    );
  }, [invoice]);

  const addAllItems = useCallback(
    (items?: InvoiceItemFieldsFragment[]) => {
      const newItems =
        (items || invoice?.items || []).map((item) => {
          const material = findMaterialByName(item.description ?? "");
          return {
            [COLUMN_TYPE.Material]:
              material?.material.name ?? item.description ?? "",
            [COLUMN_TYPE.Manufacturer]: item.manufacturer ?? "",
            [COLUMN_TYPE.UOM]: String(item.UOM) || "UT",
            [COLUMN_TYPE.Quantity]: item.quantityDecimal,
            [COLUMN_TYPE.UnitPrice]: item.unitPrice,
            [COLUMN_TYPE.ExtPrice]: String(
              calcExtPrice(item.quantityDecimal, item.unitPrice),
            ),
            [COLUMN_TYPE.CostCode]: formatCostCode(material?.costCode ?? ""),
          };
        }) || [];
      appendRows(newItems, handsonInstance);
    },
    [
      appendRows,
      calcExtPrice,
      findMaterialByName,
      formatCostCode,
      handsonInstance,
      invoice?.items,
    ],
  );

  const addMissingItems = useCallback(() => {
    addAllItems(getMissingItems());
  }, [addAllItems, getMissingItems]);

  const addInvoiceItems = useCallback(() => {
    if (handsonInstance?.isDestroyed) {
      return;
    }
    const allRowsEmpty = handsonInstance?.getData().every(rowIsEmpty);
    const noMatches = invoice?.items.length === getMissingItems().length;
    if (allRowsEmpty || noMatches) {
      addAllItems();
      return;
    }
    showModal(
      {
        handleCustomButtonAction: addAllItems,
        handleConfirm: addMissingItems,
      },
      invoice?.items.length || 0,
      getMissingItems().length,
    );
  }, [
    handsonInstance,
    rowIsEmpty,
    showModal,
    addAllItems,
    addMissingItems,
    invoice?.items.length,
    getMissingItems,
  ]);

  return {
    addInvoiceItems,
  };
};
