import { INVOICE_READONLY_STATUSES } from "@/distributor/pages/invoices/providers/DistributorInvoiceProvider";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useInvoiceVerification } from "./InvoiceVerificationProvider";

export enum MatchedOrderViewState {
  DEFAULT = "DEFAULT",
  MATCH_ORDER = "MATCH_ORDER",
  CREATE_ORDER = "CREATE_ORDER",
  EDIT_INVOICE_COVERAGES = "EDIT_INVOICE_COVERAGES",
  EDIT_ORDER = "EDIT_ORDER",
  FULFILLED = "FULFILLED",
}

type ProviderContextType = {
  matchedOrderViewState: MatchedOrderViewState;
  setMatchedOrderViewState: (status: MatchedOrderViewState) => void;
  selectedReleaseId: string | null;
  setSelectedReleaseId: (id: string | null) => void;
  preselectedPoNumber: string;
  setPreselectedPoNumber: (poNumber: string) => void;
  addMissingInvoiceItems: boolean;
  setAddMissingInvoiceItems: (add: boolean) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  matchedOrderViewState: MatchedOrderViewState.DEFAULT,
  setMatchedOrderViewState: NoFunction,
  selectedReleaseId: null,
  setSelectedReleaseId: NoFunction,
  preselectedPoNumber: "",
  setPreselectedPoNumber: NoFunction,
  addMissingInvoiceItems: false,
  setAddMissingInvoiceItems: NoFunction,
});

export const InvoiceMatchedOrderProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { invoice } = useInvoiceVerification();
  const [matchedOrderViewState, setMatchedOrderViewState] = useState(
    MatchedOrderViewState.DEFAULT,
  );
  const [selectedReleaseId, setSelectedReleaseId] = useState<string | null>(
    null,
  );
  const [initialized, setInitialized] = useState(false);
  const [preselectedPoNumber, setPreselectedPoNumber] = useState("");
  const [addMissingInvoiceItems, setAddMissingInvoiceItems] = useState(false);

  useEffect(() => {
    if (
      invoice &&
      !INVOICE_READONLY_STATUSES.includes(invoice?.status) &&
      !initialized
    ) {
      setMatchedOrderViewState(MatchedOrderViewState.EDIT_INVOICE_COVERAGES);
      setInitialized(true);
    }
  }, [invoice, initialized, setMatchedOrderViewState]);

  return (
    <ProviderContext.Provider
      value={{
        matchedOrderViewState,
        setMatchedOrderViewState,
        selectedReleaseId,
        setSelectedReleaseId,
        preselectedPoNumber,
        setPreselectedPoNumber,
        addMissingInvoiceItems,
        setAddMissingInvoiceItems,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceMatchedOrder = (): ProviderContextType =>
  useContext(ProviderContext);
