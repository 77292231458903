import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS, QUERYSTRING } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { defaultIfUndefined } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/defaultIfUndefined";
import {
  CreateDeliverySlipInput,
  DeliverySlipPartialFieldsFragment,
  DeliverySlipStatsDocument,
  DeliverySlipStatus,
  DeliverySlipsDocument,
  QueryDeliverySlipsFilter,
  useCreateDeliverySlipMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useArchiveDeliverySlip } from "../hooks/useArchiveDeliverySlip";
import { useDeliverySlipsWithPagination } from "../hooks/useDeliverySlipsWithPagination";

type ProviderContextType = {
  deliverySlips: DeliverySlipPartialFieldsFragment[];
  isFiltered: boolean;
  filter?: QueryDeliverySlipsFilter | undefined;
  setFilter: (filter: QueryDeliverySlipsFilter | undefined) => void;
  loading: boolean;
  error: boolean;
  totalCount: number;
  archiveDeliverySlip: (id: string) => Promise<boolean>;
  createDeliverySlip: (
    input: CreateDeliverySlipInput,
  ) => Promise<DeliverySlipPartialFieldsFragment | null | undefined>;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  deliverySlips: [],
  isFiltered: false,
  filter: undefined,
  setFilter: NoFunction,
  loading: false,
  error: false,
  totalCount: 0,
  archiveDeliverySlip: NoFunctionBooleanPromise,
  createDeliverySlip: NoFunctionUndefinedPromise,
  exportEnabled: false,
  setExportEnabled: NoFunction,
});

const queryParamToFilter = (
  queryParams: URLSearchParams,
  defaultValue?: QueryDeliverySlipsFilter,
): QueryDeliverySlipsFilter => {
  const search = queryParams.get(QUERYSTRING.SEARCH);
  const projectId = queryParams.get(QUERYSTRING.PROJECT_ID);
  const status = queryParams.get(QUERYSTRING.DELIVERY_SLIP_STATUS);
  const sellerOrgLocationId = queryParams.get(QUERYSTRING.SELLER_ORG_IDS);
  const minCreatedAt = queryParams.get(QUERYSTRING.MIN_CREATED_AT);
  const maxCreatedAt = queryParams.get(QUERYSTRING.MAX_CREATED_AT);
  const minFulfillmentDate = queryParams.get(QUERYSTRING.MIN_FULFILLMENT_DATE);
  const maxFulfillmentDate = queryParams.get(QUERYSTRING.MAX_FULFILLMENT_DATE);
  const archived = queryParams.get(QUERYSTRING.ARCHIVED)
    ? queryParams.get(QUERYSTRING.ARCHIVED) === "true"
    : defaultIfUndefined(defaultValue?.archived, false);
  const closedProjects = queryParams.get(QUERYSTRING.CLOSED_PROJECTS)
    ? queryParams.get(QUERYSTRING.CLOSED_PROJECTS) === "true"
    : defaultIfUndefined(defaultValue?.closedProjects, false);
  return {
    ...defaultValue,
    archived,
    search,
    projectIDs: projectId ? [projectId] : undefined,
    statuses: status ? (status.split(",") as DeliverySlipStatus[]) : undefined,
    sellerOrgLocationIds: sellerOrgLocationId
      ? [sellerOrgLocationId]
      : undefined,
    minCreatedAt: minCreatedAt ? Number(minCreatedAt) : undefined,
    maxCreatedAt: maxCreatedAt ? Number(maxCreatedAt) : undefined,
    minFulfillmentDate: minFulfillmentDate
      ? Number(minFulfillmentDate)
      : undefined,
    maxFulfillmentDate: maxFulfillmentDate
      ? Number(maxFulfillmentDate)
      : undefined,
    closedProjects,
  };
};

export const DeliverySlipsProvider: FC<{
  children: React.ReactNode;
  filter?: QueryDeliverySlipsFilter;
}> = ({ children }) => {
  const { queryParams } = useQueryParams();
  const { setError } = useGlobalError();
  const { setPage } = usePagination();
  const { readValue, setValue } = useLocalStorage();
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);

  const [filter, setFilter] = useState<QueryDeliverySlipsFilter | undefined>(
    queryParamToFilter(queryParams),
  );

  const { deliverySlips, loading, error, totalCount, pagination } =
    useDeliverySlipsWithPagination(filter);

  useEffect(() => {
    const localStorageSettings = readValue<QueryDeliverySlipsFilter>(
      LOCAL_STORAGE_KEYS.DELIVERY_SLIPS_LIST_FILTER,
    ) as QueryDeliverySlipsFilter;
    setFilter(
      queryParamToFilter(queryParams, {
        archived: localStorageSettings?.archived,
        closedProjects: localStorageSettings?.closedProjects,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { archiveDeliverySlip } = useArchiveDeliverySlip({
    filter,
    pagination,
  });

  const [createDeliverySlipMutation] = useCreateDeliverySlipMutation();
  const createDeliverySlip = async (input: CreateDeliverySlipInput) => {
    try {
      const { data, errors } = await createDeliverySlipMutation({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: DeliverySlipsDocument,
            variables: { ...pagination, filter },
          },
          {
            query: DeliverySlipStatsDocument,
          },
        ],
      });

      setError(errors);
      return data?.createDeliverySlip;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  const setFilterAndUpdateQueryString = (
    filter: QueryDeliverySlipsFilter | undefined,
  ) => {
    setPage({
      page: 0,
      queryParams: {
        [QUERYSTRING.SEARCH]: filter?.search ? filter.search : undefined,
        [QUERYSTRING.PROJECT_ID]: filter?.projectIDs,
        [QUERYSTRING.SELLER_ORG_IDS]: filter?.sellerOrgLocationIds,
        [QUERYSTRING.DELIVERY_SLIP_STATUS]: filter?.statuses?.length
          ? filter.statuses
          : undefined,
        [QUERYSTRING.MIN_CREATED_AT]: filter?.minCreatedAt
          ? `${filter.minCreatedAt}`
          : undefined,
        [QUERYSTRING.MAX_CREATED_AT]: filter?.maxCreatedAt
          ? `${filter?.maxCreatedAt}`
          : undefined,
        [QUERYSTRING.MIN_FULFILLMENT_DATE]: filter?.minFulfillmentDate
          ? `${filter.minFulfillmentDate}`
          : undefined,
        [QUERYSTRING.MAX_FULFILLMENT_DATE]: filter?.maxFulfillmentDate
          ? `${filter?.maxFulfillmentDate}`
          : undefined,
        [QUERYSTRING.CLOSED_PROJECTS]: filter?.closedProjects,
      } as never,
    });
    setFilter(filter);
    setValue(LOCAL_STORAGE_KEYS.DELIVERY_SLIPS_LIST_FILTER, filter);
  };

  const isFiltered = useMemo(
    () =>
      !!filter?.search ||
      !!filter?.projectIDs?.length ||
      !!filter?.sellerOrgLocationIds?.length ||
      !!filter?.statuses?.length ||
      !!filter?.minCreatedAt ||
      !!filter?.maxCreatedAt ||
      !!filter?.minFulfillmentDate ||
      !!filter?.maxFulfillmentDate ||
      !!filter?.closedProjects,
    [filter],
  );

  return (
    <ProviderContext.Provider
      value={{
        deliverySlips: deliverySlips ?? [],
        isFiltered,
        loading,
        error: !!error,
        totalCount,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        archiveDeliverySlip,
        createDeliverySlip,
        exportEnabled,
        setExportEnabled,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliverySlips = (): ProviderContextType =>
  useContext(ProviderContext);
