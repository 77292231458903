import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import {
  ItemizedNonItemizedToggle,
  ViewType,
} from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { useAddInvoiceItems } from "@/common/hooks/add-missing-items-to-order/useAddInvoiceItems";
import { usePreviousValue } from "@/common/hooks/usePreviousValue";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { AddItemsToReleaseFromBuyoutDialog } from "@/contractor/pages/home/release/pages/add-items-to-release/AddItemsToReleaseFromBuyoutDialog";
import { SelectableItem } from "@/contractor/pages/home/release/pages/add-items-to-release/AddItemsToReleaseProvider";
import { useOrderItemPoItemReferences } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderItemPoItemReferences";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AdditionalChargesFieldsFragment,
  UpdateVendorReleaseInput,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useCopyInvoicePrices } from "../../../../hooks/useCopyInvoicePrices";
import { useInvoiceMatchedOrder } from "../../../../providers/InvoiceMatchedOrderProvider";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";
import { InvoiceSelectModeHeader } from "../invoice-select-mode-header/InvoiceSelectModeHeader";
import { InvoiceUpdateReleaseHeader } from "./InvoiceUpdateReleaseHeader";
import { InvoiceUpdateReleaseItemizedView } from "./InvoiceUpdateReleaseItemizedView";
import { InvoiceUpdateReleaseNonItemizedView } from "./InvoiceUpdateReleaseNonItemizedView";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const HeaderGroup = tw.div`flex w-full place-items-center gap-1 mb-2`;
const ViewToggleContainer = tw.div`flex flex-1 justify-end`;
const OrderTitle = tw.div`text-base font-medium mx-1`;
const ButtonGroup = tw.div`flex flex-row justify-end gap-x-2 self-stretch mb-2`;

export type ReleaseItemType = {
  id: string;
  material: string;
  costCode: string;
  notes: string;
  UOM: string;
  quantityDecimal: string;
  unitPrice: string;
  extPrice: number;
};

export const InvoiceUpdateRelease: FC = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { release } = useRelease();
  const { buyout } = useContractorBuyout();
  const { materials } = useMaterials();
  const { appendRows, getFormattedMaterialName } = useTableHelpers();
  const { handsonInstance, spreadsheetData, setMetadata } = useColumnMapper();
  const { calcExtPrice } = usePriceCalculation();
  const { calcTableTotal } = useTableHelpers();
  const { setGlobalVendorId } = useVendorPrices();
  const { addMissingInvoiceItems, setAddMissingInvoiceItems } =
    useInvoiceMatchedOrder();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();
  const { addInvoiceItems } = useAddInvoiceItems();
  const { copyPriceFromInvoice } = useCopyInvoicePrices();

  const form = useFormContext<InvoiceCreateReleaseFormValues>();
  const { watch, setValue } = form;
  const taxRate = watch("taxRate");
  const customTaxAmount = watch("customTaxAmount");
  const subtotal = watch("subtotal");

  const [addFromBuyoutDialog, setAddFromBuyoutDialog] =
    useState<boolean>(false);
  const [releaseItems, setReleaseItems] = useState<ReleaseItemType[]>([]);
  const [itemized, setItemized] = useState(true);

  const additionalCharges = watch(
    "additionalCharges",
  ) as AdditionalChargesFieldsFragment[];

  const vendorId = watch("vendorId");
  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
  }, [setGlobalVendorId, vendorId]);

  useEffect(() => {
    const newReleaseItems = release?.items
      ?.toSorted((a, b) => (a.position || 0) - (b.position || 0))
      .map((item) => {
        const material = materials.find(
          (m) => m.id === item.projectItem?.material.id,
        );
        if (
          hasOrderItemPoItemReferences(item, release) &&
          item.position !== undefined &&
          item.position !== null
        ) {
          setMetadata(item.position, {
            warningIconTooltipText: intl.$t(
              { id: "ITEM_WILL_NEED_TO_BE_MANUALLY_ADDED_TO_EXTERNAL_PO" },
              { sourceSystem: connectedSourceSystem },
            ),
          });
        }
        return {
          id: item.id,
          material: material
            ? getFormattedMaterialName(material)
            : (item.name ?? ""),
          name: item.name ?? "",
          costCode: item.costCode?.description ?? undefined,
          notes: item.instructions?.text,
          UOM: item?.uom?.pluralDescription ?? item?.uom?.mnemonic,
          quantityDecimal: item?.quantityDecimal,
          unitPrice: item?.unitPrice,
          extPrice: calcExtPrice(item.quantityDecimal, item.unitPrice),
        };
      });

    setReleaseItems(newReleaseItems as ReleaseItemType[]);
  }, [
    calcExtPrice,
    release?.items,
    materials,
    getFormattedMaterialName,
    setMetadata,
    connectedSourceSystem,
    release?.poLink,
    intl,
    release,
    hasOrderItemPoItemReferences,
  ]);

  const previousAddMissingInvoiceItems = usePreviousValue(
    addMissingInvoiceItems,
  );
  useEffect(() => {
    if (
      previousAddMissingInvoiceItems === false &&
      addMissingInvoiceItems === true
    ) {
      setTimeout(() => {
        addInvoiceItems();
        setAddMissingInvoiceItems(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMissingInvoiceItems, addInvoiceItems]);

  const addBuyoutItems = useCallback(
    (items: SelectableItem[]) => {
      const newItems =
        buyout?.items
          .filter((item) => items.some((i) => i.itemId === item.id))
          .map((item) => {
            const material = materials.find(
              (m) => m.id === item.projectItem.material.id,
            );
            return {
              [COLUMN_TYPE.Material]: material
                ? getFormattedMaterialName(material)
                : (item.description ?? ""),
              [COLUMN_TYPE.Manufacturer]: item.manufacturer?.name || "",
              [COLUMN_TYPE.UOM]:
                item.projectItem.estimateUom.pluralDescription || "UT",
              [COLUMN_TYPE.Quantity]: item.quantityDecimal || "",
              [COLUMN_TYPE.ReceivedQuantity]: item.quantityDecimal || "",
              [COLUMN_TYPE.UnitPrice]: item.unitPrice || "",
              [COLUMN_TYPE.ExtPrice]: String(
                calcExtPrice(item.quantityDecimal, item.unitPrice),
              ),
              [COLUMN_TYPE.CostCode]: item.costCode?.description || "",
            };
          }) || [];
      appendRows(newItems, handsonInstance);
    },
    [
      buyout?.items,
      appendRows,
      handsonInstance,
      materials,
      getFormattedMaterialName,
      calcExtPrice,
    ],
  );

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== watch("subtotal")) {
        setValue("subtotal", newTotal);
      }
    },
    [calcTableTotal, setValue, watch],
  );

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  const total = useMemo(() => {
    const subTotal = new Decimal(subtotal || 0);
    const taxAmount = taxRate
      ? subTotal.mul(taxRate)
      : new Decimal(customTaxAmount || 0);
    const additionalChargesTotal = additionalCharges.reduce(
      (acc, charge) => new Decimal(acc).add(charge.amount || 0),
      new Decimal(0),
    );
    return subTotal.plus(taxAmount).plus(additionalChargesTotal).toNumber();
  }, [additionalCharges, customTaxAmount, subtotal, taxRate]);

  const handleUpdateRelease = (values: UpdateVendorReleaseInput) => {
    if (values.additionalCharges) {
      setValue("additionalCharges", values.additionalCharges);
    }
    if (
      values.customTaxAmount ||
      (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
    ) {
      setValue(
        "customTaxAmount",
        values.customTaxAmount || release?.taxAmount || "0",
      );
      setValue("taxRate", undefined);
    }
    if (values.taxRate || values.clearCustomTaxAmount) {
      setValue("customTaxAmount", undefined);
      setValue("taxRate", values.taxRate || "0");
    }
    if (values.paymentTerm) {
      setValue("paymentTerm", `${values.paymentTerm}`);
    }

    return true;
  };

  return (
    <Container>
      <HeaderGroup>
        <InvoiceSelectModeHeader className="mb-0" />
        <FormattedMessage
          id="EDIT_ORDER_WITH_NUMBER"
          tagName={OrderTitle}
          values={{
            orderNumber: release?.sequenceNumber,
          }}
        />
        <ReleaseStatusChip
          status={release?.status}
          releaseType={release?.type}
          type="breadcrumb"
        />
        <ViewToggleContainer>
          <ItemizedNonItemizedToggle
            handleChange={(view) => setItemized(view === ViewType.Itemized)}
            isItemized={itemized}
          />
        </ViewToggleContainer>
      </HeaderGroup>
      <ButtonGroup>
        <OutlinedButton
          $small
          id="copy-prices"
          onClick={copyPriceFromInvoice}
          className="ml-2"
        >
          <FormattedMessage id="COPY_PRICES" />
        </OutlinedButton>
        <If isTrue={buyout}>
          <OutlinedButton $small onClick={() => setAddFromBuyoutDialog(true)}>
            <FormattedMessage id="ADD_ITEMS_FROM_BUYOUT" />
          </OutlinedButton>
          <AddItemsToReleaseFromBuyoutDialog
            visible={addFromBuyoutDialog}
            setVisible={setAddFromBuyoutDialog}
            callback={addBuyoutItems}
          />
        </If>
        <OutlinedButton $small onClick={() => addInvoiceItems()}>
          <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_INVOICE" />
        </OutlinedButton>
      </ButtonGroup>
      <InvoiceUpdateReleaseHeader />
      <If isTrue={itemized}>
        <InvoiceUpdateReleaseItemizedView
          releaseItems={releaseItems}
          total={total}
          updateSubtotal={updateSubtotal}
          handleUpdateRelease={handleUpdateRelease}
          form={form}
          additionalCharges={additionalCharges}
        />
      </If>
      <If isTrue={!itemized}>
        <InvoiceUpdateReleaseNonItemizedView form={form} />
      </If>
    </Container>
  );
};
