import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS, QUERYSTRING } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { defaultIfUndefined } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/defaultIfUndefined";
import {
  CreateReceiptInput,
  InvoiceStatus,
  InvoiceType,
  QueryInvoicesFilter,
  ReceiptSummaryFieldsFragment,
  useReceiptsQuery,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionStringPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useArchiveReceipt } from "../hooks/useArchiveReceipt";
import { useCreateReceipt } from "../hooks/useCreateReceipt";

type ProviderContextType = {
  receipts: ReceiptSummaryFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  filter?: QueryInvoicesFilter;
  setFilter: (filter: QueryInvoicesFilter) => void;
  isFiltered: boolean;
  createReceipt: (input: CreateReceiptInput) => Promise<string | undefined>;
  archiveReceipt: (receiptId: string) => Promise<boolean>;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
  creating: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  receipts: [],
  totalCount: 0,
  loading: false,
  error: false,
  filter: undefined,
  setFilter: NoFunction,
  isFiltered: false,
  createReceipt: NoFunctionStringPromise,
  archiveReceipt: NoFunctionBooleanPromise,
  exportEnabled: false,
  setExportEnabled: NoFunction,
  creating: false,
});

const queryParamToFilter = (
  queryParams: URLSearchParams,
  defaultValue?: QueryInvoicesFilter,
): QueryInvoicesFilter => {
  const projectId = queryParams.get(QUERYSTRING.PROJECT_ID);
  const statuses = queryParams.get(QUERYSTRING.STATUSES);
  const minIssueDate = queryParams.get(QUERYSTRING.MIN_ISSUE_DATE)
    ? Number(queryParams.get(QUERYSTRING.MIN_ISSUE_DATE))
    : undefined;
  const maxIssueDate = queryParams.get(QUERYSTRING.MAX_ISSUE_DATE)
    ? Number(queryParams.get(QUERYSTRING.MAX_ISSUE_DATE))
    : undefined;
  const minCreatedAt = queryParams.get(QUERYSTRING.MIN_CREATED_AT)
    ? Number(queryParams.get(QUERYSTRING.MIN_CREATED_AT))
    : undefined;
  const maxCreatedAt = queryParams.get(QUERYSTRING.MAX_CREATED_AT)
    ? Number(queryParams.get(QUERYSTRING.MAX_CREATED_AT))
    : undefined;
  const archived = queryParams.get(QUERYSTRING.ARCHIVED)
    ? queryParams.get(QUERYSTRING.ARCHIVED) === "true"
    : defaultIfUndefined(defaultValue?.archived, false);
  const closedProjects = queryParams.get(QUERYSTRING.CLOSED_PROJECTS)
    ? queryParams.get(QUERYSTRING.CLOSED_PROJECTS) === "true"
    : defaultIfUndefined(defaultValue?.closedProjects, false);
  return {
    ...defaultValue,
    projectIds: projectId ? [projectId] : undefined,
    statuses: statuses
      ? statuses.split(",").map((status) => status as InvoiceStatus)
      : undefined,
    minIssueDate,
    maxIssueDate,
    minCreatedAt,
    maxCreatedAt,
    archived,
    closedProjects,
  };
};

export enum ReceiptType {
  Approved = "APPROVED",
  AwaitingApproval = "AWAITING_APPROVAL",
  Paid = "PAID",
}

export const ReceiptsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { queryParams } = useQueryParams();
  const { paginationArgs, page, setPageInfo, previousPage, setPage } =
    usePagination();
  const { readValue, setValue } = useLocalStorage();

  const [filter, setFilter] = useState<QueryInvoicesFilter | undefined>({
    projectIds: queryParams.get(QUERYSTRING.PROJECT_ID)
      ? [queryParams.get(QUERYSTRING.PROJECT_ID) as string]
      : undefined,
    statuses: queryParams.get(QUERYSTRING.STATUSES)
      ? ((queryParams.get(QUERYSTRING.STATUSES) || "").split(
          ",",
        ) as InvoiceStatus[])
      : undefined,
    minIssueDate: queryParams.get(QUERYSTRING.MIN_ISSUE_DATE)
      ? Number(queryParams.get(QUERYSTRING.MIN_ISSUE_DATE))
      : undefined,
    maxIssueDate: queryParams.get(QUERYSTRING.MAX_ISSUE_DATE)
      ? Number(queryParams.get(QUERYSTRING.MAX_ISSUE_DATE))
      : undefined,
    minCreatedAt: queryParams.get(QUERYSTRING.MIN_CREATED_AT)
      ? Number(queryParams.get(QUERYSTRING.MIN_CREATED_AT))
      : undefined,
    maxCreatedAt: queryParams.get(QUERYSTRING.MAX_CREATED_AT)
      ? Number(queryParams.get(QUERYSTRING.MAX_CREATED_AT))
      : undefined,
    archived: queryParams.get(QUERYSTRING.ARCHIVED)
      ? queryParams.get(QUERYSTRING.ARCHIVED) === "true"
      : false,
    closedProjects: queryParams.get(QUERYSTRING.CLOSED_PROJECTS)
      ? queryParams.get(QUERYSTRING.CLOSED_PROJECTS) === "true"
      : false,
    type: InvoiceType.Receipt,
  });
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);
  const { createReceipt: createReceiptHook, loading: creating } =
    useCreateReceipt();

  const setFilterWithType = useCallback((filter: QueryInvoicesFilter) => {
    setFilter({
      ...filter,
      type: InvoiceType.Receipt,
    });
  }, []);

  const setFilterAndUpdateQueryString = useCallback(
    (updatedFilter: QueryInvoicesFilter) => {
      setPage({
        page: 0,
        queryParams: {
          [QUERYSTRING.STATUSES]: updatedFilter.statuses,
          [QUERYSTRING.PROJECT_ID]: updatedFilter?.projectIds,
          [QUERYSTRING.MIN_ISSUE_DATE]: updatedFilter?.minIssueDate
            ? `${updatedFilter.minIssueDate}`
            : undefined,
          [QUERYSTRING.MAX_ISSUE_DATE]: updatedFilter?.maxIssueDate
            ? `${updatedFilter?.maxIssueDate}`
            : undefined,
          [QUERYSTRING.MIN_CREATED_AT]: updatedFilter?.minCreatedAt
            ? `${updatedFilter.minCreatedAt}`
            : undefined,
          [QUERYSTRING.MAX_CREATED_AT]: updatedFilter?.maxCreatedAt
            ? `${updatedFilter?.maxCreatedAt}`
            : undefined,
          [QUERYSTRING.ARCHIVED]: updatedFilter?.archived,
          [QUERYSTRING.CLOSED_PROJECTS]: updatedFilter?.closedProjects,
        },
      });
      setValue(LOCAL_STORAGE_KEYS.RECEIPTS_LIST_FILTER, updatedFilter);
      setFilterWithType(updatedFilter);
    },
    [setFilterWithType, setPage, setValue],
  );

  useEffect(() => {
    const localStorageSettings = readValue<QueryInvoicesFilter>(
      LOCAL_STORAGE_KEYS.RECEIPTS_LIST_FILTER,
    ) as QueryInvoicesFilter;
    setFilterWithType(
      queryParamToFilter(queryParams, {
        archived: localStorageSettings?.archived,
        exported: localStorageSettings?.exported,
        prePaid: localStorageSettings?.prePaid,
        closedProjects: localStorageSettings?.closedProjects,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page !== 0 && data?.receipts.edges.length === 0) {
      previousPage();
    }
  });

  const { data, loading, error, refetch } = useReceiptsQuery({
    variables: {
      filter,
      ...paginationArgs,
    },
    fetchPolicy: "cache-and-network",
  });

  const { archiveReceipt: archiveReceiptFn } = useArchiveReceipt({
    filter,
    page,
    paginationArgs,
    previousPage,
  });

  useEffect(() => {
    if (data?.receipts) {
      setPageInfo(data.receipts.pageInfo);
    }
  }, [data, setPageInfo]);

  const createReceipt = async (input: CreateReceiptInput) => {
    const receiptId = await createReceiptHook(input);
    if (receiptId) {
      await refetch();
      return receiptId;
    }
  };

  const archiveReceipt = (id: string) => {
    return archiveReceiptFn(id);
  };

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        receipts: data?.receipts?.edges?.map((edge) => edge.node) || [],
        loading,
        error: !!error,
        totalCount: data?.receipts?.totalCount || 0,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        isFiltered: !!filter?.statuses?.length || !!filter?.projectIds?.length,
        createReceipt,
        archiveReceipt,
        exportEnabled,
        setExportEnabled,
        creating,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceipts = (): ProviderContextType =>
  useContext(ProviderContext);
