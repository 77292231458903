import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS, QUERYSTRING } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useRfqMutations } from "@/contractor/pages/home/rfq/hooks/useRfqMutations";
import {
  QueryRfQsFilter,
  RfqStatus,
  RfqsRfqFieldsFragment,
  useRfqsQuery,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { defaultIfUndefined } from "../../invoices/pages/scanned-invoices/providers/defaultIfUndefined";

enum RFQsFilterSelection {
  Active = "ACTIVE",
  Awarded = "AWARDED",
  Cancelled = "CANCELLED",
  Draft = "DRAFT",
}

type ProviderContextType = {
  rfqs: RfqsRfqFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  removeRfq: (rfqId: string) => Promise<boolean>;
  filter?: QueryRfQsFilter;
  isFiltered: boolean;
  setFilter: (filter: QueryRfQsFilter) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  rfqs: [],
  totalCount: 0,
  loading: false,
  error: false,
  removeRfq: NoFunctionBooleanPromise,
  filter: undefined,
  setFilter: NoFunction,
  isFiltered: false,
});

export const FILTER_OPTIONS = [
  {
    id: RFQsFilterSelection.Draft,
  },
  {
    id: RFQsFilterSelection.Active,
  },
  {
    id: RFQsFilterSelection.Awarded,
  },
  {
    id: RFQsFilterSelection.Cancelled,
  },
];

const queryParamToFilter = (
  queryParams: URLSearchParams,
  defaultValue?: QueryRfQsFilter,
): QueryRfQsFilter => {
  const projectId = queryParams.get(QUERYSTRING.PROJECT_ID);
  const statuses = queryParams.get(QUERYSTRING.RFQ_FILTER_STATUSES);
  const closedProjects = queryParams.get(QUERYSTRING.CLOSED_PROJECTS)
    ? queryParams.get(QUERYSTRING.CLOSED_PROJECTS) === "true"
    : defaultIfUndefined(defaultValue?.closedProjects, false);
  const deleted = queryParams.get(QUERYSTRING.ARCHIVED)
    ? queryParams.get(QUERYSTRING.ARCHIVED) === "true"
    : defaultIfUndefined(defaultValue?.deleted, false);
  return {
    ...defaultValue,
    projectIds: projectId ? projectId.split(",") : undefined,
    statuses: statuses ? (statuses.split(",") as RfqStatus[]) : undefined,
    deleted,
    closedProjects,
  };
};

export const RfqsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { queryParams, setQueryParams } = useQueryParams();
  const { removeRfqMutation } = useRfqMutations();
  const { paginationArgs, page, setPageInfo, previousPage, setPage } =
    usePagination();
  const { readValue, setValue } = useLocalStorage();
  const [filter, setFilter] = useState<QueryRfQsFilter | undefined>(
    queryParamToFilter(queryParams),
  );

  useEffect(() => {
    const localStorageSettings = readValue<QueryRfQsFilter>(
      LOCAL_STORAGE_KEYS.RFQS_LIST_FILTER,
    ) as QueryRfQsFilter;
    setFilter(
      queryParamToFilter(queryParams, {
        deleted: localStorageSettings?.deleted,
        quotesReceived: localStorageSettings?.quotesReceived,
        closedProjects: localStorageSettings?.closedProjects,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilterAndUpdateQueryString = (updatedFilter: QueryRfQsFilter) => {
    setPage({ page: 0 });
    setFilter(updatedFilter);
    setValue(LOCAL_STORAGE_KEYS.RFQS_LIST_FILTER, updatedFilter);
    setQueryParams({
      [QUERYSTRING.RFQ_FILTER_STATUSES]: updatedFilter.statuses,
      [QUERYSTRING.PROJECT_ID]: updatedFilter?.projectIds,
      [QUERYSTRING.ARCHIVED]: updatedFilter?.deleted,
      [QUERYSTRING.CLOSED_PROJECTS]: updatedFilter?.closedProjects,
    });
  };

  const { data, loading, error } = useRfqsQuery({
    variables: {
      filter,
      ...paginationArgs,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.rfqs) {
      setPageInfo(data.rfqs.pageInfo);
    }
  }, [data, setPageInfo]);

  const removeRfq = async (rfqId: string) => {
    if (page !== 0 && data?.rfqs.edges.length === 1) {
      previousPage();
    }

    return await removeRfqMutation(rfqId);
  };

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        rfqs: data?.rfqs?.edges?.map((edge) => edge.node) || [],
        loading,
        error: !!error,
        totalCount: data?.rfqs?.totalCount || 0,
        removeRfq,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        isFiltered:
          !!filter?.statuses?.length ||
          !!filter?.projectIds?.length ||
          filter?.deleted !== false,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useRfqs = (): ProviderContextType => useContext(ProviderContext);
