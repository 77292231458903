import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  NestedWizardModalPage,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { DEFAULT_ITEMS_PER_PAGE, LUMP_SUM_UOM } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  ExternalPOsPaginatedOutput,
  useExternalPOsWithPagination,
} from "@/common/hooks/useExternalPOsWithPagination";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  ExternalPOItem,
  ExternalPOTableCategoryId,
} from "@/contractor/pages/home/common/external-po/ExternalPOItemsTable.configuration";
import {
  DeliverySlipItemFieldsFragment,
  ExternalPOsQueryInput,
  ExternalPoBaseFieldsFragment,
  ExternalPoFieldsFragment,
  ExternalPoItemFieldsFragment,
  ManufacturerFieldsFragment,
  OrgMaterialFieldsFragment,
  SourceSystem,
  ViewerCostCodesDocument,
  useExternalPoQuery,
  useImportCostCodesMutation,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import Decimal from "decimal.js";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UseFormReturn, useForm, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDebounce } from "use-debounce";
import { DeliverySlipReleaseFormValues } from "../components/delivery-slip-verification/components/delivery-slip-form/DeliverySlipVerificationForm";
import { DeliverySlipExternalPOItems } from "../components/delivery-slip-verification/delivery-slip-import-external-po/delivery-slip-external-po-items/DeliverySlipExternalPOItems";
import { DeliverySlipExternalPOs } from "../components/delivery-slip-verification/delivery-slip-import-external-po/delivery-slip-external-pos/DeliverySlipExternalPOs";
import {
  PackingSlipMatchViewState,
  useDeliverySlipVerification,
} from "./DeliverySlipVerificationProvider";

export type DeliverySlipMappedItem = DeliverySlipItemFieldsFragment & {
  receivedQuantityDecimal: string | null | undefined;
  material:
    | (OrgMaterialFieldsFragment & {
        manufacturer: ManufacturerFieldsFragment;
      })
    | undefined;
  costCode: string | undefined;
  manufacturer: string | undefined;
  UOM: string | null | undefined;
  unitPrice: string | undefined;
};

type ProviderContextType = {
  importModalOpened: boolean;
  openModal: ({ skipPoList }: { skipPoList: boolean }) => void;
  closeModal: () => void;
  resetImportedData: () => void;
  modalPages: WizardModalPage[];

  externalPOsQueryForm:
    | UseFormReturn<
        Pick<
          ExternalPOsQueryInput,
          "projectId" | "sellerOrgLocationID" | "search" | "minDate" | "maxDate"
        >,
        unknown
      >
    | undefined;
  externalPOsPaginatedOutput: ExternalPOsPaginatedOutput;
  selectedBaseExternalPO: ExternalPoBaseFieldsFragment | undefined;
  setSelectedBaseExternalPO: (
    baseExternalPO: ExternalPoBaseFieldsFragment | undefined,
  ) => void;

  externalPo: ExternalPoFieldsFragment | null | undefined;
  loadingExternalPo: boolean;

  includedItemsCategory: CategoryState<ExternalPOItem>[];
  missingTagsCategory: CategoryState<ExternalPOItem>[];
  missingCostCodesCategory: CategoryState<ExternalPOItem>[];
  unsupportedCostTypesCategory: CategoryState<ExternalPOItem>[];
  aggregatedItems: ExternalPoItemFieldsFragment[] | undefined;

  importExternalCostCodes: () => void;
  importingExternalCostCodes: boolean;

  selectedSellerOrgLocationId: string | null;
  setSelectedSellerOrgLocationId: (id: string | null) => void;
  importedPoExternalId: string | null;
  importedPoItemized: boolean | null;
  importedItems: null | DeliverySlipMappedItem[];

  orderTypeId: string | null;
  setOrderTypeId: (id: string | null) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  importModalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,
  resetImportedData: NoFunction,
  modalPages: [],

  externalPOsQueryForm: undefined,
  externalPOsPaginatedOutput: {
    externalPOs: [],
    loadingExternalPOs: false,
    page: 1,
    count: 0,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    hasPrevious: false,
    hasNext: false,
    nextPage: NoFunction,
    previousPage: NoFunction,
    setPageFn: NoFunction,
    setPageSizeFn: NoFunction,
  },
  selectedBaseExternalPO: undefined,
  setSelectedBaseExternalPO: NoFunction,

  externalPo: null,
  loadingExternalPo: false,

  includedItemsCategory: [],
  missingTagsCategory: [],
  missingCostCodesCategory: [],
  unsupportedCostTypesCategory: [],
  aggregatedItems: [],

  importExternalCostCodes: NoFunction,
  importingExternalCostCodes: false,

  selectedSellerOrgLocationId: null,
  setSelectedSellerOrgLocationId: NoFunction,
  importedPoExternalId: null,
  importedPoItemized: null,
  importedItems: null,

  setOrderTypeId: NoFunction,
  orderTypeId: null,
});

export const DeliverySlipImportExternalPOProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { setSuccessAlert } = useSnackbar();
  const { connectedSourceSystem } = useOrgSettings();
  const { moveToNextStep, setStep } = useNestedStepper();
  const { deliverySlip, setPackingSlipMatchViewState, setSelectedRelease } =
    useDeliverySlipVerification();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const [importModalOpened, setImportModalOpened] = useState(false);
  const [selectedBaseExternalPO, setSelectedBaseExternalPO] = useState<
    ExternalPoBaseFieldsFragment | undefined
  >();
  const [selectedSellerOrgLocationId, setSelectedSellerOrgLocationId] =
    useState<string | null>(null);
  const [importedItems, setImportedItems] = useState<
    DeliverySlipMappedItem[] | null
  >(null);

  const [importedPoExternalId, setImportedPoExternalId] = useState<
    string | null
  >(null);
  const [importedPoItemized, setImportedPoItemized] = useState<boolean | null>(
    null,
  );

  const [orderTypeId, setOrderTypeId] = useState<string | null>(null);

  const { defaultOrderType } = useOrderTypeOptions();
  const { getUomByName } = useUomOptions();
  const { includePoNumbering } = usePoNumberingSettingsCheck();

  useEffect(() => {
    if (defaultOrderType && !orderTypeId) {
      setOrderTypeId(defaultOrderType.id);
    }
  }, [defaultOrderType, orderTypeId]);
  const externalPOsQueryForm = useForm<
    Pick<
      ExternalPOsQueryInput,
      "projectId" | "sellerOrgLocationID" | "search" | "minDate" | "maxDate"
    >
  >({
    defaultValues: {
      projectId: undefined,
      sellerOrgLocationID: undefined,
      search: undefined,
      minDate: undefined,
      maxDate: undefined,
    },
    mode: "onChange",
  });

  const projectId = externalPOsQueryForm.watch("projectId");
  const sellerOrgLocationID = externalPOsQueryForm.watch("sellerOrgLocationID");
  const search = externalPOsQueryForm.watch("search");
  const minDate = externalPOsQueryForm.watch("minDate");
  const maxDate = externalPOsQueryForm.watch("maxDate");

  const externalPOsPaginatedOutput = useExternalPOsWithPagination({
    sourceSystem: connectedSourceSystem,
    projectId: !!projectId && projectId !== "" ? projectId : undefined,
    sellerOrgLocationID:
      !!sellerOrgLocationID && sellerOrgLocationID !== ""
        ? sellerOrgLocationID
        : undefined,
    search: search ?? undefined,
    minDate: minDate ? Number(minDate) : undefined,
    maxDate: maxDate ? Number(maxDate) : undefined,
    skip: !includePoNumbering,
  });

  const { watch, setValue } = useFormContext<DeliverySlipReleaseFormValues>();

  const deliverySlipCreateReleaseFormPoNumber = watch("poNumber");
  const deliverySlipCreateReleaseFormProjectId = watch("projectId");

  const [debouncedDeliverySlipCreateReleaseFormPoNumber] = useDebounce(
    deliverySlipCreateReleaseFormPoNumber,
    800,
  );

  const externalPoQueryOptions = useMemo(() => {
    if (selectedBaseExternalPO) {
      return {
        variables: {
          input: {
            sourceSystem: connectedSourceSystem ?? SourceSystem.Foundation,
            externalId: selectedBaseExternalPO.externalId,
            projectId: hasFeatureInConnectedSourceSystem(
              IntegrationFeature.ExternalPoReadsProjectSpecific,
            )
              ? selectedBaseExternalPO.project?.project?.id
              : undefined,
            releaseTypeId: orderTypeId,
          },
        },
        skip:
          !connectedSourceSystem ||
          !selectedBaseExternalPO.externalId ||
          (hasFeatureInConnectedSourceSystem(
            IntegrationFeature.ExternalPoReadsProjectSpecific,
          )
            ? !selectedBaseExternalPO.project?.project?.id
            : false),
      };
    }
    if (debouncedDeliverySlipCreateReleaseFormPoNumber) {
      return {
        variables: {
          input: {
            sourceSystem: connectedSourceSystem ?? SourceSystem.Foundation,
            number: debouncedDeliverySlipCreateReleaseFormPoNumber,
            projectId:
              !!connectedSourceSystem &&
              hasFeatureInConnectedSourceSystem(
                IntegrationFeature.ExternalPoReadsProjectSpecific,
              )
                ? deliverySlipCreateReleaseFormProjectId
                : undefined,
          },
        },
        skip:
          !connectedSourceSystem ||
          !debouncedDeliverySlipCreateReleaseFormPoNumber ||
          (hasFeatureInConnectedSourceSystem(
            IntegrationFeature.ExternalPoReadsProjectSpecific,
          )
            ? !deliverySlipCreateReleaseFormProjectId
            : false),
      };
    }
    return {
      variables: {
        input: {
          sourceSystem: connectedSourceSystem ?? SourceSystem.Foundation,
          number: deliverySlip?.release?.poNumber,
          projectId:
            !!connectedSourceSystem &&
            hasFeatureInConnectedSourceSystem(
              IntegrationFeature.ExternalPoReadsProjectSpecific,
            )
              ? deliverySlip?.release?.project?.id
              : undefined,
        },
      },
      skip:
        !connectedSourceSystem ||
        !deliverySlip?.release?.poNumber ||
        (hasFeatureInConnectedSourceSystem(
          IntegrationFeature.ExternalPoReadsProjectSpecific,
        )
          ? !deliverySlip?.release?.project?.id
          : false),
    };
  }, [
    selectedBaseExternalPO,
    debouncedDeliverySlipCreateReleaseFormPoNumber,
    connectedSourceSystem,
    deliverySlip?.release?.poNumber,
    deliverySlip?.release?.project?.id,
    hasFeatureInConnectedSourceSystem,
    deliverySlipCreateReleaseFormProjectId,
    orderTypeId,
  ]);

  const {
    data,
    loading: loadingExternalPo,
    error,
    refetch,
  } = useExternalPoQuery({
    ...externalPoQueryOptions,
    nextFetchPolicy: "network-only",
  });

  useErrorEffect(error);

  useEffect(() => {
    if (
      data?.externalPO?.vendor?.orgPreferredVendors?.[0]?.sellerOrgLocation.id
    ) {
      setSelectedSellerOrgLocationId(
        data?.externalPO?.vendor?.orgPreferredVendors?.[0]?.sellerOrgLocation
          .id,
      );
    }
  }, [data]);

  const [importCostCodesMutation, { loading: importingExternalCostCodes }] =
    useImportCostCodesMutation();
  const importExternalCostCodes = useCallback(async () => {
    try {
      if (
        (data?.externalPO?.itemGroups.missingCostCode ?? []).length > 0 &&
        connectedSourceSystem
      ) {
        const externalCostCodeIds = (
          data?.externalPO?.itemGroups.missingCostCode ?? []
        )
          .filter((c) => !!c.externalCostCode?.externalId)
          .map((c) => c.externalCostCode?.externalId);

        await importCostCodesMutation({
          variables: {
            input: {
              sourceSystem: connectedSourceSystem,
              externalCostCodeIds: externalCostCodeIds as string[],
            },
          },
          refetchQueries: [{ query: ViewerCostCodesDocument }],
        });

        await refetch();
      }
    } catch (error) {
      setError(error);
    }
  }, [
    data?.externalPO,
    connectedSourceSystem,
    refetch,
    setError,
    importCostCodesMutation,
  ]);

  const missingTagsCategory = useMemo(
    () =>
      (data?.externalPO?.itemGroups.missingTag ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.MISSING_TAG,
              isOpened: true,
              name: intl.$t({ id: "MISSING_PHASE_CODES" }),
              description: intl.$t({ id: "MISSING_PHASE_CODES_TOOLTIP" }),
              items: (data?.externalPO?.itemGroups.missingTag ?? []).map(
                (i) => ({
                  ...i,
                  id: i.externalId,
                }),
              ),
            },
          ]
        : [],
    [data?.externalPO?.itemGroups.missingTag, intl],
  );

  const missingCostCodesCategory = useMemo(
    () =>
      (data?.externalPO?.itemGroups.missingCostCode ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.MISSING_COST_CODES,
              isOpened: true,
              name: intl.$t({ id: "MISSING_COST_CODES" }),
              description: intl.$t({ id: "MISSING_COST_CODES_TOOLTIP" }),
              items: (data?.externalPO?.itemGroups.missingCostCode ?? []).map(
                (i) => ({ ...i, id: i.externalId }),
              ),
            },
          ]
        : [],
    [data?.externalPO?.itemGroups.missingCostCode, intl],
  );

  const unsupportedCostTypesCategory = useMemo(
    () =>
      (data?.externalPO?.itemGroups.unsupportedCostType ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.UNSUPPORTED_COST_TYPES,
              isOpened: true,
              name: intl.$t({ id: "UNSUPPORTED_COST_TYPES" }),
              description: intl.$t({ id: "UNSUPPORTED_COST_TYPES_TOOLTIP" }),
              items: (
                data?.externalPO?.itemGroups.unsupportedCostType ?? []
              ).map((i) => ({ ...i, id: i.externalId })),
            },
          ]
        : [],
    [data?.externalPO?.itemGroups.unsupportedCostType, intl],
  );

  const includedItemsCategory = useMemo(
    () => [
      {
        id: ExternalPOTableCategoryId.INCLUDED,
        isOpened: true,
        name: "",
        items: [
          ...(data?.externalPO?.itemGroups.importable ?? []),
          ...(data?.externalPO?.itemGroups.additionalCharges ?? []),
          ...(data?.externalPO?.itemGroups.salesTax ?? []),
        ].map((i) => ({ ...i, id: i.externalId })),
      },
    ],
    [data?.externalPO?.itemGroups],
  );

  const aggregatedItems = useMemo(
    () => data?.externalPO?.itemGroups.aggregatedItems,
    [data?.externalPO?.itemGroups],
  );

  const isNonItemizedPO = useMemo(
    () => (data?.externalPO?.itemGroups.aggregatedItems ?? []).length > 0,
    [data?.externalPO],
  );

  const importExternalPO = useCallback(() => {
    if (data?.externalPO) {
      setSelectedRelease(null);
      if (!isNonItemizedPO) {
        setImportedItems(
          (data.externalPO.itemGroups.importable ?? []).map((i) => {
            const isLumpSum = getUomByName(i.uom)?.mnemonic === LUMP_SUM_UOM;
            return {
              id: i.externalId,
              description: i.description,
              material: undefined,
              costCode: i.costCode?.description ?? i.wbsTag?.name,
              UOM: i.uom,
              unitPrice: isLumpSum ? i.amount : i.unitCost,
              receivedQuantityDecimal: isLumpSum ? "1" : i.quantity,
              quantityDecimal: isLumpSum ? "1" : i.quantity,
              manufacturer: undefined,
            };
          }),
        );
      }

      if (data.externalPO.itemGroups.additionalCharges) {
        setValue(
          "additionalCharges",
          data.externalPO.itemGroups.additionalCharges.map((i) => ({
            amount: i.amount,
            description: i.description,
            id: i.externalId,
          })),
        );
      }

      if (data.externalPO.itemGroups.salesTax) {
        setValue(
          "customTaxAmount",
          data.externalPO.itemGroups.salesTax
            .reduce(
              (acc, next) =>
                new Decimal(acc).add(new Decimal(next.amount)).toNumber(),
              0,
            )
            .toString(),
        );
      }

      if (selectedSellerOrgLocationId) {
        setValue("vendorId", selectedSellerOrgLocationId);
      }

      if (data.externalPO?.project?.project) {
        setValue(
          "businessLocationId",
          data.externalPO.project.project.location.id,
        );
        setValue("projectId", data.externalPO.project.project.id);
      }

      setValue("poNumber", data.externalPO.number);

      setImportedPoExternalId(data?.externalPO?.externalId);
      setImportedPoItemized(!isNonItemizedPO);
      setPackingSlipMatchViewState(PackingSlipMatchViewState.CREATE_ORDER);

      setImportModalOpened(false);

      setSuccessAlert(
        intl.$t({
          id: isNonItemizedPO
            ? "NON_ITEMIZED_EXTERNAL_PO_ASSOCIATED"
            : "EXTERNAL_PO_IMPORTED",
        }),
      );
    }
  }, [
    data?.externalPO,
    setSelectedRelease,
    isNonItemizedPO,
    selectedSellerOrgLocationId,
    setValue,
    setPackingSlipMatchViewState,
    setSuccessAlert,
    intl,
    getUomByName,
  ]);

  const openModal = useCallback(
    ({ skipPoList }: { skipPoList: boolean }) => {
      setStep(skipPoList ? 1 : 0);
      setImportModalOpened(true);
    },
    [setStep],
  );

  const resetImportedData = useCallback(() => {
    setSelectedSellerOrgLocationId(null);
    setImportedPoExternalId(null);
    setImportedPoItemized(null);
    setImportedItems(null);
    externalPOsQueryForm.reset();
  }, [externalPOsQueryForm]);

  const closeModal = useCallback(() => {
    setImportModalOpened(false);
    setSelectedBaseExternalPO(undefined);
  }, []);

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: null,
        pages: [
          {
            hideHeader: true,
            component: <DeliverySlipExternalPOs />,
            onNextClick: () => {
              moveToNextStep();
            },
            onNextClickDisabled: !selectedBaseExternalPO,
            onCloseClick: closeModal,
          } as NestedWizardModalPage,
        ],
      },
      {
        title: null,
        pages: [
          {
            hideHeader: true,
            component: <DeliverySlipExternalPOItems />,
            onNextClick:
              loadingExternalPo || (data?.externalPO?.poLinks ?? []).length > 0
                ? undefined
                : () => importExternalPO(),
            onNextClickDisabled:
              (isNonItemizedPO
                ? false
                : includedItemsCategory[0].items.length === 0) ||
              !data?.externalPO?.project?.project ||
              (data?.externalPO?.vendor?.orgPreferredVendors ?? []).length ===
                0 ||
              (data?.externalPO?.poLinks ?? []).length > 0,
            onNextLabel: intl.$t({
              id: isNonItemizedPO ? "ASSOCIATE_PO_WITH_ORDER" : "IMPORT",
            }),
            onNextClassName: isNonItemizedPO ? "w-60" : "",
            onCloseClick: closeModal,
          } as NestedWizardModalPage,
        ],
      },
    ],
    [
      selectedBaseExternalPO,
      closeModal,
      loadingExternalPo,
      data?.externalPO?.poLinks,
      data?.externalPO?.project?.project,
      data?.externalPO?.vendor?.orgPreferredVendors,
      isNonItemizedPO,
      includedItemsCategory,
      intl,
      moveToNextStep,
      importExternalPO,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        importModalOpened,
        openModal,
        closeModal,
        resetImportedData,
        modalPages: pages,

        externalPOsQueryForm,
        externalPOsPaginatedOutput,
        selectedBaseExternalPO,
        setSelectedBaseExternalPO,

        externalPo: data?.externalPO,
        loadingExternalPo,

        includedItemsCategory,
        missingTagsCategory,
        missingCostCodesCategory,
        unsupportedCostTypesCategory,
        aggregatedItems,

        importExternalCostCodes,
        importingExternalCostCodes,

        selectedSellerOrgLocationId,
        setSelectedSellerOrgLocationId,
        importedPoExternalId,
        importedPoItemized,
        importedItems,

        setOrderTypeId,
        orderTypeId,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliverySlipImportExternalPO = (): ProviderContextType =>
  useContext(ProviderContext);
