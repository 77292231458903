import { useRoles } from "@/common/components/org-roles-wrapper/hasRoles";
import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS, QUERYSTRING } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { evictCacheById } from "@/common/utils/cacheUtils";
import {
  InvoiceEmailsDocument,
  InvoiceEmailStatus,
  namedOperations,
  OrgRole,
  QueryInvoiceEmailsFilter,
  useArchiveInvoiceEmailMutation,
  useInvoiceEmailsQuery,
} from "@/generated/graphql";
import { useEffect } from "react";
import { defaultIfUndefined } from "../../scanned-invoices/providers/defaultIfUndefined";
import { useInvoicesStore } from "../store/useInvoicesStore";

const queryParamToFilter = (
  queryParams: URLSearchParams,
  defaultValue?: QueryInvoiceEmailsFilter | null,
): QueryInvoiceEmailsFilter => {
  const statuses = queryParams.get(QUERYSTRING.INVOICE_EMAIL_STATUSES)
    ? ((queryParams.get(QUERYSTRING.INVOICE_EMAIL_STATUSES) || "").split(
        ",",
      ) as InvoiceEmailStatus[])
    : undefined;

  const archived = queryParams.get(QUERYSTRING.ARCHIVED)
    ? queryParams.get(QUERYSTRING.ARCHIVED) === "true"
    : defaultIfUndefined(defaultValue?.archived, false);

  const sellerOrgLocationIds = queryParams.get(QUERYSTRING.SELLER_ORG_IDS)
    ? (queryParams.get(QUERYSTRING.SELLER_ORG_IDS) || "").split(",")
    : undefined;

  return {
    ...defaultValue,
    sellerOrgLocationIds,
    statuses,
    archived,
  };
};

export const useInvoiceEmails = (
  {
    skipQuery,
  }:
    | {
        skipQuery?: boolean;
      }
    | undefined = { skipQuery: false },
) => {
  const { queryParams, setQueryParams } = useQueryParams();
  const { paginationArgs, page, setPageInfo, previousPage, setPage } =
    usePagination();
  const [archiveInvoiceEmailMutation] = useArchiveInvoiceEmailMutation();
  const { setError } = useGlobalError();
  const { filter, setFilter } = useInvoicesStore();
  const { readValue, setValue } = useLocalStorage();
  const { hasRoles: canSeeInbox } = useRoles({
    roles: [OrgRole.OrgAdmin, OrgRole.OrgAccountant],
  });

  const setFilterAndUpdateQueryString = (
    updatedFilter: QueryInvoiceEmailsFilter,
  ) => {
    setPage({ page: 0 });
    setQueryParams({
      [QUERYSTRING.INVOICE_EMAIL_STATUSES]: updatedFilter.statuses,
      [QUERYSTRING.SELLER_ORG_IDS]: updatedFilter?.sellerOrgLocationIds,
      [QUERYSTRING.ARCHIVED]: updatedFilter?.archived,
    });
    setFilter(updatedFilter);
    setValue(LOCAL_STORAGE_KEYS.INVOICE_EMAILS_LIST_FILTER, updatedFilter);
  };

  useEffect(() => {
    const localStorageSettings = readValue<QueryInvoiceEmailsFilter>(
      LOCAL_STORAGE_KEYS.INVOICE_EMAILS_LIST_FILTER,
    ) as QueryInvoiceEmailsFilter;

    setFilter(
      queryParamToFilter(queryParams, {
        archived: localStorageSettings?.archived,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilter]);

  const { data, loading, error, refetch } = useInvoiceEmailsQuery({
    variables: {
      filter,
      ...paginationArgs,
    },
    skip: skipQuery,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.invoiceEmails) {
      setPageInfo(data.invoiceEmails.pageInfo);
    }
  }, [data, setPageInfo]);

  const archiveInvoiceEmail = async (id: string) => {
    try {
      const { errors } = await archiveInvoiceEmailMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: InvoiceEmailsDocument,
            variables: { ...paginationArgs, filter },
          },
        ],
        update: (cache, { data: archiveData }) =>
          evictCacheById(
            cache,
            namedOperations.Query.InvoiceEmails,
            archiveData?.archiveInvoiceEmail.id,
          ),
      });

      if (page !== 0 && data?.invoiceEmails.edges.length === 1) {
        previousPage();
      }
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  useErrorEffect(error);

  return {
    invoiceEmails: data?.invoiceEmails?.edges?.map((edge) => edge.node) || [],
    loading,
    error: !!error,
    totalCount: data?.invoiceEmails?.totalCount || 0,
    archiveInvoiceEmail,
    filter,
    setFilter: setFilterAndUpdateQueryString,
    isFiltered:
      !!filter?.statuses?.length || !!filter?.sellerOrgLocationIds?.length,
    refetch,
    canSeeInbox,
  };
};
