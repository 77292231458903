import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS, QUERYSTRING } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { defaultIfUndefined } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/defaultIfUndefined";
import { useScheduleRelease } from "@/contractor/pages/home/release/hooks/useScheduleRelease";
import {
  QueryReleasesFilter,
  ReleasePartialFieldsFragment,
  ReleaseStatus,
  ReleasesDocument,
  ScheduleReleaseInput,
  useArchiveReleaseMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useDeliveriesWithPagination } from "../hooks/useDeliveriesWithPagination";

type ProviderContextType = {
  deliveries: ReleasePartialFieldsFragment[];
  filter?: QueryReleasesFilter | undefined;
  setFilter: (filter: QueryReleasesFilter | undefined) => void;
  loading: boolean;
  error: boolean;
  totalCount: number;
  archiveRelease: (id: string) => Promise<boolean>;
  isFiltered: boolean;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
  scheduleRelease: (input: ScheduleReleaseInput) => Promise<boolean>;
  refetch: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  deliveries: [],
  filter: undefined,
  setFilter: NoFunction,
  loading: false,
  error: false,
  totalCount: 0,
  archiveRelease: NoFunctionBooleanPromise,
  isFiltered: false,
  exportEnabled: false,
  setExportEnabled: NoFunction,
  scheduleRelease: NoFunctionBooleanPromise,
  refetch: NoFunction,
});

const queryParamToFilter = (
  queryParams: URLSearchParams,
  defaultValue?: QueryReleasesFilter | null,
): QueryReleasesFilter => {
  const projectId = queryParams.get(QUERYSTRING.PROJECT_ID);
  const status = queryParams.get(QUERYSTRING.DELIVERY_STATUS);
  const sellerOrgLocationId = queryParams.get(QUERYSTRING.SELLER_ORG_IDS);
  const backordered = queryParams.get(QUERYSTRING.BACKORDERED)
    ? queryParams.get(QUERYSTRING.BACKORDERED) === "true"
    : defaultValue?.isBackorder;
  const deleted = queryParams.get(QUERYSTRING.ARCHIVED)
    ? queryParams.get(QUERYSTRING.ARCHIVED) === "true"
    : defaultIfUndefined(defaultValue?.deleted, false);
  const closedProjects = queryParams.get(QUERYSTRING.CLOSED_PROJECTS)
    ? queryParams.get(QUERYSTRING.CLOSED_PROJECTS) === "true"
    : defaultIfUndefined(defaultValue?.closedProjects, false);
  return {
    ...defaultValue,
    projectIds: projectId ? [projectId] : undefined,
    statuses: status ? (status.split(",") as ReleaseStatus[]) : undefined,
    sellerOrgLocationIds: sellerOrgLocationId
      ? [sellerOrgLocationId]
      : undefined,
    isBackorder: backordered,
    deleted,
    closedProjects,
  };
};

export const DeliveriesProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { queryParams } = useQueryParams();
  const { setError } = useGlobalError();
  const { setPage } = usePagination();

  const { readValue, setValue } = useLocalStorage();

  const [filter, setFilter] = useState<QueryReleasesFilter | undefined>(
    queryParamToFilter(queryParams),
  );

  const { deliveries, loading, error, totalCount, pagination, refetch } =
    useDeliveriesWithPagination(filter);
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);

  useEffect(() => {
    const localStorageSettings = readValue<QueryReleasesFilter>(
      LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER,
    ) as QueryReleasesFilter;
    setFilter(
      queryParamToFilter(queryParams, {
        statuses: localStorageSettings?.statuses,
        deleted: localStorageSettings?.deleted,
        exported: localStorageSettings?.exported,
        retroactive: localStorageSettings?.retroactive,
        isBackorder: localStorageSettings?.isBackorder,
        hasReceipt: localStorageSettings?.hasReceipt,
        closedProjects: localStorageSettings?.closedProjects,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { scheduleReleaseMutation } = useScheduleRelease();
  const scheduleRelease = async (input: ScheduleReleaseInput) => {
    const result = await scheduleReleaseMutation(input);
    if (result) {
      refetch();
    }
    return result;
  };

  const [archiveDeliveryMutation] = useArchiveReleaseMutation();
  const archiveRelease = async (id: string) => {
    try {
      const { errors } = await archiveDeliveryMutation({
        variables: {
          id,
        },
        refetchQueries: [
          { query: ReleasesDocument, variables: { ...pagination, filter } },
        ],
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const setFilterAndUpdateQueryString = (
    updatedFilter: QueryReleasesFilter | undefined,
  ) => {
    setPage({
      page: 0,
      queryParams: {
        [QUERYSTRING.DELIVERY_STATUS]: updatedFilter?.statuses
          ? updatedFilter.statuses
          : undefined,
        [QUERYSTRING.PROJECT_ID]: updatedFilter?.projectIds,
        [QUERYSTRING.SELLER_ORG_IDS]: updatedFilter?.sellerOrgLocationIds,
        [QUERYSTRING.BACKORDERED]:
          filter?.isBackorder !== undefined
            ? updatedFilter?.isBackorder
            : undefined,
        [QUERYSTRING.ARCHIVED]: updatedFilter?.deleted,
        [QUERYSTRING.EXPORTED]: updatedFilter?.exported,
        [QUERYSTRING.CLOSED_PROJECTS]: updatedFilter?.closedProjects,
      } as never,
    });
    setValue(LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER, updatedFilter);
    setFilter(updatedFilter);
  };

  return (
    <ProviderContext.Provider
      value={{
        deliveries,
        isFiltered: !!filter?.projectIds || !!filter?.statuses,
        loading,
        error: !!error,
        totalCount,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        archiveRelease,
        exportEnabled,
        setExportEnabled,
        scheduleRelease,
        refetch,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliveries = (): ProviderContextType =>
  useContext(ProviderContext);
