import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS, QUERYSTRING } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useInvoiceCreation } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceCreationProvider";
import {
  CreateInvoicesInput,
  InvoiceFieldsFragment,
  InvoiceStatus,
  InvoiceSummaryFieldsFragment,
  QueryInvoicesFilter,
  useInvoicesQuery,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionArrayPromise,
  NoFunctionBooleanPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useArchiveInvoice } from "../hooks/useArchiveInvoice";
import { defaultIfUndefined } from "./defaultIfUndefined";

type ProviderContextType = {
  invoices: InvoiceSummaryFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  filter?: QueryInvoicesFilter;
  setFilter: (filter: QueryInvoicesFilter) => void;
  isFiltered: boolean;
  createInvoices: (
    input: CreateInvoicesInput,
  ) => Promise<InvoiceFieldsFragment[] | undefined>;
  archiveInvoice: (invoiceId: string) => Promise<boolean>;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
  creating: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  invoices: [],
  totalCount: 0,
  loading: false,
  error: false,
  filter: undefined,
  setFilter: NoFunction,
  isFiltered: false,
  createInvoices: NoFunctionArrayPromise,
  archiveInvoice: NoFunctionBooleanPromise,
  exportEnabled: false,
  setExportEnabled: NoFunction,
  creating: false,
});

const queryParamToFilter = (
  queryParams: URLSearchParams,
  defaultValue?: QueryInvoicesFilter | null,
): QueryInvoicesFilter => {
  const search = queryParams.get(QUERYSTRING.SEARCH);
  const projectId = queryParams.get(QUERYSTRING.PROJECT_ID);
  const statuses = queryParams.get(QUERYSTRING.STATUSES);
  const minIssueDate = queryParams.get(QUERYSTRING.MIN_ISSUE_DATE)
    ? Number(queryParams.get(QUERYSTRING.MIN_ISSUE_DATE))
    : undefined;
  const maxIssueDate = queryParams.get(QUERYSTRING.MAX_ISSUE_DATE)
    ? Number(queryParams.get(QUERYSTRING.MAX_ISSUE_DATE))
    : undefined;
  const kickbackToMe = queryParams.get(QUERYSTRING.KICKBACK_TO_ME)
    ? queryParams.get(QUERYSTRING.KICKBACK_TO_ME) === "true"
    : defaultValue?.kickbackToMe;
  const archived = queryParams.get(QUERYSTRING.ARCHIVED)
    ? queryParams.get(QUERYSTRING.ARCHIVED) === "true"
    : defaultIfUndefined(defaultValue?.archived, false);
  const exported = queryParams.get(QUERYSTRING.EXPORTED)
    ? queryParams.get(QUERYSTRING.EXPORTED) === "true"
    : defaultIfUndefined(defaultValue?.exported, false);
  const closedProjects = queryParams.get(QUERYSTRING.CLOSED_PROJECTS)
    ? queryParams.get(QUERYSTRING.CLOSED_PROJECTS) === "true"
    : defaultIfUndefined(defaultValue?.closedProjects, false);
  return {
    ...defaultValue,
    search,
    projectIds: projectId ? [projectId] : undefined,
    statuses: statuses ? (statuses.split(",") as InvoiceStatus[]) : undefined,
    minIssueDate,
    maxIssueDate,
    kickbackToMe,
    archived,
    exported,
    closedProjects,
  };
};

export const InvoicesProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { queryParams } = useQueryParams();
  const { paginationArgs, page, setPageInfo, previousPage, setPage } =
    usePagination();
  const { readValue, setValue } = useLocalStorage();

  const [filter, setFilter] = useState<QueryInvoicesFilter | undefined>(
    queryParamToFilter(queryParams),
  );
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);
  const { createInvoices: createInvoicesMutation, creating } =
    useInvoiceCreation();

  const setFilterAndUpdateQueryString = useCallback(
    (updatedFilter: QueryInvoicesFilter) => {
      setPage({
        page: 0,
        queryParams: {
          [QUERYSTRING.SEARCH]: updatedFilter.search,
          [QUERYSTRING.STATUSES]: updatedFilter.statuses,
          [QUERYSTRING.PROJECT_ID]: updatedFilter?.projectIds,
          [QUERYSTRING.MIN_ISSUE_DATE]: updatedFilter?.minIssueDate
            ? `${updatedFilter.minIssueDate}`
            : undefined,
          [QUERYSTRING.MAX_ISSUE_DATE]: updatedFilter?.maxIssueDate
            ? `${updatedFilter?.maxIssueDate}`
            : undefined,
          [QUERYSTRING.KICKBACK_TO_ME]: updatedFilter?.kickbackToMe,
          [QUERYSTRING.ARCHIVED]: updatedFilter?.archived,
          [QUERYSTRING.EXPORTED]: updatedFilter?.exported,
          [QUERYSTRING.CLOSED_PROJECTS]: updatedFilter?.closedProjects,
        },
      });
      setFilter(updatedFilter);
      setValue(LOCAL_STORAGE_KEYS.INVOICES_LIST_FILTER, updatedFilter);
    },
    [setPage, setValue],
  );

  useEffect(() => {
    const localStorageSettings = readValue<QueryInvoicesFilter>(
      LOCAL_STORAGE_KEYS.INVOICES_LIST_FILTER,
    ) as QueryInvoicesFilter;
    setFilter(
      queryParamToFilter(queryParams, {
        statuses: localStorageSettings?.statuses,
        kickbackToMe: localStorageSettings?.kickbackToMe,
        archived: localStorageSettings?.archived,
        exported: localStorageSettings?.exported,
        poExported: localStorageSettings?.poExported,
        receiptPosted: localStorageSettings?.receiptPosted,
        closedProjects: localStorageSettings?.closedProjects,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page !== 0 && data?.invoices.edges.length === 0) {
      previousPage();
    }
  });

  const { data, loading, error, refetch } = useInvoicesQuery({
    variables: {
      filter,
      ...paginationArgs,
    },
    fetchPolicy: "cache-and-network",
  });

  const { archiveInvoice: archiveInvoiceFn } = useArchiveInvoice({
    filter,
    page,
    paginationArgs,
    previousPage,
  });

  useEffect(() => {
    if (data?.invoices) {
      setPageInfo(data.invoices.pageInfo);
    }
  }, [data, setPageInfo]);

  const createInvoices = async (input: CreateInvoicesInput) => {
    const createdInvoices = await createInvoicesMutation(input);
    await refetch();
    return createdInvoices;
  };

  const archiveInvoice = async (id: string) => {
    return await archiveInvoiceFn(id);
  };

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        invoices: data?.invoices?.edges?.map((edge) => edge.node) || [],
        loading,
        error: !!error,
        totalCount: data?.invoices?.totalCount || 0,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        isFiltered: !!filter?.statuses?.length || !!filter?.projectIds?.length,
        createInvoices,
        archiveInvoice,
        exportEnabled,
        setExportEnabled,
        creating,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoices = (): ProviderContextType =>
  useContext(ProviderContext);
