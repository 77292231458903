import { ExternalPOItems } from "@/contractor/pages/home/common/external-po/ExternalPOItems";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { useInvoiceImportExternalPO } from "../../../../../providers/InvoiceImportExternalPoProvider";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";

export const InvoiceExternalPOItems: FC = () => {
  const intl = useIntl();

  const { invoice } = useInvoiceVerification();
  const {
    closeModal,
    externalPo,
    loadingExternalPo,
    importExternalCostCodes,
    importingExternalCostCodes,
    externalPOsQueryForm,
    includedItemsCategory,
    missingTagsCategory,
    unsupportedCostTypesCategory,
    missingCostCodesCategory,
    selectedSellerOrgLocationId,
    selectedBaseExternalPO,
    setSelectedSellerOrgLocationId,
    orderTypeId,
    setOrderTypeId,
    hasMissingPoLink,
  } = useInvoiceImportExternalPO();

  const projectId = externalPOsQueryForm?.watch("projectId");

  const poAlreadyImported = useMemo(
    () =>
      selectedBaseExternalPO?.poLinks.some(
        (poLink) => poLink.release?.id === invoice?.release?.id,
      ) ||
      externalPo?.poLinks.some(
        (poLink) => poLink.release?.id === invoice?.release?.id,
      ),
    [
      selectedBaseExternalPO?.poLinks,
      externalPo?.poLinks,
      invoice?.release?.id,
    ],
  );

  const isNonItemizedPO = useMemo(
    () => (externalPo?.itemGroups.aggregatedItems ?? []).length > 0,
    [externalPo],
  );

  const validationError = useMemo(() => {
    if (poAlreadyImported || hasMissingPoLink) {
      return null;
    }
    if (
      !selectedBaseExternalPO?.project?.project &&
      !externalPo?.project?.project
    ) {
      return intl.$t(
        { id: "PO_IMPORT_FM_PROJECT_NOT_IMPORTED" },
        {
          projectName:
            selectedBaseExternalPO?.project?.name ??
            externalPo?.project?.name ??
            "",
        },
      );
    }
    if (
      (selectedBaseExternalPO?.vendor?.orgPreferredVendors ?? []).length ===
        0 &&
      (externalPo?.vendor?.orgPreferredVendors ?? []).length === 0
    ) {
      return intl.$t(
        { id: "PO_IMPORT_FM_VENDOR_NOT_IMPORTED" },
        {
          vendorName:
            selectedBaseExternalPO?.vendor?.name ??
            externalPo?.vendor?.name ??
            "",
        },
      );
    }
    if (
      ((selectedBaseExternalPO?.poLinks ?? []).length > 0 ||
        (externalPo?.poLinks ?? []).length > 0) &&
      !isNonItemizedPO
    ) {
      return intl.$t({ id: "EXTERNAL_PO_ORDER_ALREADY_ASSOCIATED" });
    }
    return null;
  }, [
    poAlreadyImported,
    hasMissingPoLink,
    selectedBaseExternalPO?.project?.project,
    selectedBaseExternalPO?.project?.name,
    selectedBaseExternalPO?.vendor?.orgPreferredVendors,
    selectedBaseExternalPO?.vendor?.name,
    selectedBaseExternalPO?.poLinks,
    externalPo?.project?.project,
    externalPo?.project?.name,
    externalPo?.vendor?.orgPreferredVendors,
    externalPo?.vendor?.name,
    externalPo?.poLinks,
    intl,
    isNonItemizedPO,
  ]);

  return (
    <ExternalPOItems
      close={closeModal}
      projectId={projectId}
      externalPO={externalPo}
      loading={loadingExternalPo}
      missingCostCodesCategory={missingCostCodesCategory}
      missingTagsCategory={missingTagsCategory}
      unsupportedCostTypesCategory={unsupportedCostTypesCategory}
      includedItemsCategory={includedItemsCategory}
      readonly={poAlreadyImported || !!hasMissingPoLink}
      validationError={validationError}
      importExternalCostCodes={importExternalCostCodes}
      importingExternalCostCodes={importingExternalCostCodes}
      selectedSellerOrgLocationId={selectedSellerOrgLocationId}
      setSelectedSellerOrgLocationId={setSelectedSellerOrgLocationId}
      orderTypeId={orderTypeId}
      setOrderTypeId={setOrderTypeId}
    />
  );
};
