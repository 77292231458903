import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import {
  AdditionalChargesFieldsFragment,
  UpdateVendorReleaseInput,
} from "@/generated/graphql";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useInvoiceCreateRelease } from "../../../../providers/InvoiceCreateReleaseProvider";
import { useInvoiceImportExternalPO } from "../../../../providers/InvoiceImportExternalPoProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";
import { useInvoiceCreateReleaseSpreadsheetConfig } from "./InvoiceCreateRelease.config";

const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-full`;
const Footer = tw.div`mr-7 mt-10 w-full pb-5`;

export const InvoiceCreateReleaseItemizedView = () => {
  const { importedItems } = useInvoiceImportExternalPO();
  const { setValue, watch } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { loading, invoiceItems } = useInvoiceCreateRelease();
  const spreadsheetViewColumns = useInvoiceCreateReleaseSpreadsheetConfig();
  const { calcTableTotal } = useTableHelpers();
  const { spreadsheetData } = useColumnMapper();
  const { invoice } = useInvoiceVerification();
  const customTaxAmount = watch("customTaxAmount");

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== watch("subtotal")) {
        setValue("subtotal", newTotal);
      }
    },
    [calcTableTotal, setValue, watch],
  );

  const handleUpdateRelease = (values: UpdateVendorReleaseInput) => {
    if (values.additionalCharges) {
      setValue("additionalCharges", values.additionalCharges);
    }
    if (
      values.customTaxAmount ||
      (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
    ) {
      setValue(
        "customTaxAmount",
        values.customTaxAmount || invoice?.taxAmount || "0",
      );
      setValue("taxRate", undefined);
    }
    if (values.taxRate || values.clearCustomTaxAmount) {
      setValue("customTaxAmount", undefined);
      setValue("taxRate", values.taxRate || "0");
    }
    if (values.paymentTerm) {
      setValue("paymentTerm", `${values.paymentTerm}`);
    }

    return true;
  };

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  return (
    <>
      <SpreadSheetView>
        <SpreadSheetTable
          items={importedItems ?? invoiceItems}
          columns={spreadsheetViewColumns}
          saving={loading}
          height="380px"
          rowNumber={10}
          onChanges={updateSubtotal}
          includePermissionCheck={false}
        />
      </SpreadSheetView>
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={watch("total")}
          subtotal={`${watch("subtotal")}`}
          customTaxAmount={watch("customTaxAmount")}
          customTaxRate={watch("taxRate")}
          customPaymentTerm={watch("paymentTerm")}
          editableByContractor
          editablePaymentTerms
          updateRelease={(values) =>
            handleUpdateRelease({ ...values, version: -1 })
          }
          includePaymentTerms
          additionalCharges={
            watch("additionalCharges") as AdditionalChargesFieldsFragment[]
          }
        />
      </Footer>
    </>
  );
};
