import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { isOrgCatalogSku } from "@/common/components/material/utils";
import { LUMP_SUM_UOM, LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useEstimatedPrices } from "@/common/providers/EstimatedPricesProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { SeededRandom } from "@/common/utils/seedRadom";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectTags } from "@/contractor/pages/home/project/providers/ProjectTagsProvider";
import { useProjectZones } from "@/contractor/pages/home/project/providers/ProjectZonesProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import {
  CostCodeFieldsFragment,
  ManufacturerFieldsFragment,
  OrgCatalogSku,
  OrgMaterialFieldsFragment,
} from "@/generated/graphql";
import Handsontable from "handsontable";
import { ColumnSettings } from "handsontable/settings";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TAG_COLORS } from "../../tag-picker/TagColors";
import { vendorLabelFormatter } from "../../vendor-picker/VendorPickerCustomRender";

export const CUSTOM_SOURCE = "custom";

export const composeName = (
  material: OrgMaterialFieldsFragment,
  materials: OrgMaterialFieldsFragment[],
) => {
  const hasDuplicates =
    materials.filter((mat) => mat.material.name === material.material.name)
      .length > 1;
  const name = material.material.name;
  const partNumber = (material.material as OrgCatalogSku).customPartNumber;
  return `${name}${hasDuplicates && partNumber ? `〈${partNumber}〉` : ""}`;
};

export const useTableHelpers = () => {
  const { openDialog } = useDialog();
  const { addZones, zones } = useProjectZones();
  const { buyout } = useContractorBuyout();
  const { tags, updateTags } = useProjectTags();
  const { manufacturers, createManufacturers } = useManufacturers();
  const { materials, updateMaterials } = useMaterials();
  const { vendors } = useVendors();
  const { globalVendorId, prices } = useVendorPrices();
  const { spreadsheetData, extraColumns, config } = useColumnMapper();
  const { calcExtPrice } = usePriceCalculation();
  const { costCodes, formatCostCode } = useCostCodes();
  const { getUomByName } = useUomOptions();
  const { estimatedPrices } = useEstimatedPrices();
  const intl = useIntl();

  const sanitizeValue = useCallback((value: string) => {
    if (typeof value === "string") {
      return value
        ?.replace(/<([^>]*)>/g, "$1")
        .replace(/:\w+:/g, "")
        .replace(/\s+/g, " ")
        .trim();
    } else {
      return value;
    }
  }, []);

  const getCellValue = useCallback(
    (row: Record<string, string | number>, type: COLUMN_TYPE): string => {
      const value = (row && row[type]) ?? "";
      if (typeof value === "number") {
        return value.toString();
      }
      if (typeof value === "string" && type !== COLUMN_TYPE.CustomPartNumber) {
        return sanitizeValue(value);
      }
      return value;
    },
    [sanitizeValue],
  );

  const rowIsEmpty = useCallback((row: Record<string, string>) => {
    return Object.values(row).every((cell) => !cell || cell === row.id);
  }, []);

  const getRowUomCreatableValue = useCallback(
    (row: Record<string, string>) => {
      return getCellValue(row, COLUMN_TYPE.UOM);
    },
    [getCellValue],
  );

  const getCostCodeId = useCallback(
    (row: Record<string, string>) => {
      const rowValue = getCellValue(row, COLUMN_TYPE.CostCode);
      return costCodes.find((code) => rowValue === formatCostCode(code))?.id;
    },
    [costCodes, formatCostCode, getCellValue],
  );

  const getNameParts = useCallback((name: string) => {
    if (!name) {
      return { namePart: "", partNumber: "" };
    }
    const namePart = String(name).split("〈")?.[0].trim();
    const partNumber = String(name)
      .match(/〈(.*)〉/)?.[1]
      .trim();
    return { namePart, partNumber };
  }, []);

  const findMaterialByName = useCallback(
    (name: string, customMaterials?: OrgMaterialFieldsFragment[]) => {
      if (!name) {
        return undefined;
      }
      const { namePart, partNumber } = getNameParts(String(name));
      const hasDuplicates =
        (customMaterials ?? materials).filter((mat) => {
          return mat.material.name === namePart;
        }).length > 1;
      return (customMaterials ?? materials).find((m) => {
        const sameName = m.material.name === namePart;
        const samePartNumber =
          (m.material as OrgCatalogSku).customPartNumber ===
          (partNumber ?? null);
        return hasDuplicates ? sameName && samePartNumber : sameName;
      }) as OrgMaterialFieldsFragment & {
        manufacturer: ManufacturerFieldsFragment;
      };
    },
    [getNameParts, materials],
  );

  const getPrefilledValue = useCallback(
    (
      materialText: string,
      vendorText: string,
      manufacturerText: string,
      uomText: string,
    ) => {
      const material = findMaterialByName(materialText, materials);
      if (!material) {
        return {
          value: "",
          isVendorPrice: false,
          isEstimatedPrice: false,
        };
      }

      const vendor = vendors.find(
        (v) =>
          vendorLabelFormatter(v.sellerOrgLocation) === vendorText ||
          v.sellerOrgLocation.id === globalVendorId,
      );

      const manufacturer = manufacturers.find(
        (m) => m.name === manufacturerText,
      );

      const uom = getUomByName(uomText);
      const vendorPrice = prices.find(
        (p) =>
          p.orgMaterialId === material.id &&
          p.uom.id === uom?.id &&
          (!vendor ||
            p.sellerOrgLocation?.id === vendor?.sellerOrgLocation.id) &&
          (!manufacturer ||
            !p.manufacturer ||
            p.manufacturer?.id === manufacturer?.id),
      );

      const estimatedPrice = !vendorPrice
        ? estimatedPrices
            .find((p) => p.material.id === material.id)
            ?.prices.find(
              (price) =>
                price.uomId === uom?.id &&
                (!manufacturer ||
                  !price.manufacturerId ||
                  price.manufacturerId === manufacturer?.id),
            )
        : undefined;

      return {
        value: vendorPrice?.price || estimatedPrice?.unitPrice || "",
        isVendorPrice: !!vendorPrice,
        isEstimatedPrice: !!estimatedPrice,
      };
    },
    [
      estimatedPrices,
      findMaterialByName,
      getUomByName,
      globalVendorId,
      manufacturers,
      materials,
      prices,
      vendors,
    ],
  );

  const isBuyoutItem = useCallback(
    (
      materialText: string,
      vendorText: string,
      manufacturerText: string,
      priceText: string,
      costCodeText: string,
    ) => {
      if (!buyout) {
        return null;
      }

      const material = findMaterialByName(materialText, materials);
      const materialName = material?.material.name
        .replace(/\\/, "")
        .replace(/\s+/g, " ")
        .trim();
      const vendor = vendors.find(
        (v) =>
          vendorLabelFormatter(v.sellerOrgLocation) === vendorText ||
          v.sellerOrgLocation.id === globalVendorId,
      );
      const manufacturer = manufacturers.find(
        (m) => m.name === manufacturerText,
      );
      const code = costCodes.find(
        (code) => costCodeText === formatCostCode(code),
      );

      const items = buyout.items.map((item) => ({
        name: item.projectItem.material.material.name
          .replace(/\\/, "")
          .replace(/\s+/g, " ")
          .trim(),
        ...item,
      }));

      return items.find(
        (item) =>
          item.name === materialName &&
          (!vendor || buyout.preferredVendor?.id === vendor?.id) &&
          (!manufacturer || item.manufacturer?.id === manufacturer?.id) &&
          String(item.unitPrice) === String(priceText) &&
          (item.costCode?.id === code?.id ||
            buyout.items.filter(
              (i) =>
                i.projectItem.material.material.name ===
                material?.material.name,
            ).length === 1),
      );
    },
    [
      buyout,
      costCodes,
      findMaterialByName,
      formatCostCode,
      globalVendorId,
      manufacturers,
      materials,
      vendors,
    ],
  );

  const rowHasCostCode = useCallback(
    (row: Record<string, string>, code: CostCodeFieldsFragment) => {
      const costCodeText = getCellValue(row, COLUMN_TYPE.CostCode);
      return costCodeText === code.description || costCodeText === code.code;
    },
    [getCellValue],
  );

  const getCellWithAdditionalData = useCallback(
    (row: Record<string, string>, type: COLUMN_TYPE) => {
      const additionData: string[] = [];
      extraColumns.forEach((addCol) => {
        if (addCol.additional === type && row[addCol.columnType]) {
          additionData.push(`${addCol.header} = ${row[addCol.columnType]}`);
        }
      });
      if (additionData.length > 0) {
        const value = getCellValue(row, type).replace(/,.*(=.*)/, "");
        const valueWithAdditionalData = `${value}, ${additionData.join(", ")}`;
        return valueWithAdditionalData.replace(/\s+/g, " ").trim();
      } else {
        return getCellValue(row, type);
      }
    },
    [extraColumns, getCellValue],
  );

  const confirmRemove = useCallback(
    (
      addedItems: Array<unknown>,
      itemsToRemove: Array<unknown>,
      labels?: { title: string; text: string },
      threshold = 10,
    ) => {
      let resolveFn: (value: boolean | undefined) => void;
      const modalResult: Promise<boolean | undefined> = new Promise(
        (resolve) => {
          resolveFn = resolve;
        },
      );

      if (itemsToRemove.length - addedItems.length > threshold) {
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "PROCEED" }),
          icon: <WarningIcon />,
          title: (
            <FormattedMessage
              id={labels?.title ?? "WARNING_REMOVING_MATERIALS_TITLE"}
            />
          ),
          text: (
            <FormattedMessage
              id={labels?.text ?? "WARNING_REMOVING_MATERIALS"}
              values={{
                number: itemsToRemove.length - addedItems.length,
              }}
            />
          ),
          handleConfirm: () => {
            resolveFn(true);
          },
          handleCancel() {
            resolveFn(undefined);
          },
        });
        return modalResult;
      } else {
        return Promise.resolve(true);
      }
    },
    [intl, openDialog],
  );

  const sanitizeMaterialName = useCallback(
    (value: string) => {
      const { namePart, partNumber } = getNameParts(value);
      return `${sanitizeValue(namePart)}${partNumber ? `〈${partNumber}〉` : ""}`;
    },
    [getNameParts, sanitizeValue],
  );

  const getFormattedMaterialName = useCallback(
    (material: OrgMaterialFieldsFragment | string) => {
      if (!material) {
        return "";
      }
      if (typeof material === "string") {
        return sanitizeMaterialName(material);
      } else {
        if (isOrgCatalogSku(material?.material)) {
          return composeName(material, materials);
        } else {
          return material.material.name;
        }
      }
    },
    [materials, sanitizeMaterialName],
  );

  const addMissingMaterials = useCallback(
    async (newManufacturers: ManufacturerFieldsFragment[] = []) => {
      const materialNamesSet = new Set(
        materials.map((material) => getFormattedMaterialName(material)),
      );
      const missingMaterialsNames: Record<string, string>[] =
        spreadsheetData.filter((row) => {
          let rowMaterialName = getCellWithAdditionalData(
            row,
            COLUMN_TYPE.Material,
          );
          const rowIsLumpSum = isLumpSumUomText(
            getCellValue(row, COLUMN_TYPE.UOM),
          );
          if (rowIsLumpSum) {
            rowMaterialName = LUMP_SUM_UOM_PLURAL_DESCRIPTION;
          }
          return rowMaterialName && !materialNamesSet.has(rowMaterialName);
        });

      const uniqueMissingMaterialsNames = missingMaterialsNames.filter(
        (row, index) => {
          const mat = getCellWithAdditionalData(row, COLUMN_TYPE.Material);
          return (
            missingMaterialsNames.findIndex(
              (r) => getCellWithAdditionalData(r, COLUMN_TYPE.Material) === mat,
            ) === index
          );
        },
      );
      if (uniqueMissingMaterialsNames.length > 0) {
        const result = await updateMaterials({
          addedMaterials: uniqueMissingMaterialsNames.map((row) => {
            const rowIsLumpSum = isLumpSumUomText(
              getCellValue(row, COLUMN_TYPE.UOM),
            );
            if (rowIsLumpSum) {
              return {
                newOrgCatalogSKU: {
                  defaultUom: LUMP_SUM_UOM,
                  name: LUMP_SUM_UOM_PLURAL_DESCRIPTION,
                },
              };
            }
            return {
              newOrgCatalogSKU: {
                defaultUom: getRowUomCreatableValue(row),
                name: getNameParts(
                  getCellWithAdditionalData(row, COLUMN_TYPE.Material),
                ).namePart,
                defaultManufacturerId: [
                  ...manufacturers,
                  ...newManufacturers,
                ].find(
                  (m) => m.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
                )?.id,
                customPartNumber: getNameParts(
                  getCellWithAdditionalData(row, COLUMN_TYPE.Material),
                ).partNumber,
              },
              ...(getCellValue(row, COLUMN_TYPE.CostCode) && {
                costCodeId: costCodes.find((code) => rowHasCostCode(row, code))
                  ?.id,
              }),
            };
          }),
        });
        return result as OrgMaterialFieldsFragment[];
      } else {
        return Promise.resolve(undefined);
      }
    },
    [
      materials,
      spreadsheetData,
      getFormattedMaterialName,
      getCellWithAdditionalData,
      updateMaterials,
      getRowUomCreatableValue,
      getNameParts,
      manufacturers,
      getCellValue,
      costCodes,
      rowHasCostCode,
    ],
  );

  const addMissingManufacturers = useCallback(() => {
    const missingManufacturerNames = spreadsheetData.reduce<string[]>(
      (acc, row) => {
        const rowManufacturerName = getCellValue(row, COLUMN_TYPE.Manufacturer);
        return acc.concat(
          rowManufacturerName &&
            !manufacturers.some((m) => m.name === rowManufacturerName)
            ? [rowManufacturerName]
            : [],
        );
      },
      [],
    );

    if (missingManufacturerNames.length > 0) {
      return createManufacturers(missingManufacturerNames);
    } else {
      return Promise.resolve(undefined);
    }
  }, [createManufacturers, getCellValue, manufacturers, spreadsheetData]);

  const addMissingZones = useCallback(
    (projectId: string) => {
      const missingZoneNames = spreadsheetData.reduce<string[]>((acc, row) => {
        const rowZoneName = getCellValue(row, COLUMN_TYPE.Zone);
        return acc.concat(
          rowZoneName && !zones?.some((m) => m.name === rowZoneName)
            ? [rowZoneName]
            : [],
        );
      }, []);

      if (missingZoneNames.length > 0) {
        return addZones({
          projectId: projectId || "",
          names: missingZoneNames,
        });
      } else {
        return Promise.resolve(undefined);
      }
    },
    [addZones, getCellValue, zones, spreadsheetData],
  );

  const addMissingTags = useCallback(
    async (projectId: string) => {
      const missingTagNames = spreadsheetData.reduce<string[]>((acc, row) => {
        const rowTagName = getCellValue(row, COLUMN_TYPE.Tag);
        return acc.concat(
          rowTagName && !tags?.some((m) => m.name === rowTagName)
            ? [rowTagName]
            : [],
        );
      }, []);

      if (missingTagNames.length > 0) {
        return await updateTags({
          projectId: projectId || "",
          addedTags: [...new Set(missingTagNames)].map((tagName) => {
            const random = new SeededRandom(tagName || "NA");
            const color = TAG_COLORS[random.nextInt(TAG_COLORS.length)];
            return {
              name: tagName,
              color,
            };
          }),
        });
      } else {
        return undefined;
      }
    },
    [updateTags, getCellValue, tags, spreadsheetData],
  );

  const getPhysicalColumnIndex = useCallback(
    (hotInstance: Handsontable | null | undefined, column: COLUMN_TYPE) => {
      const columns = hotInstance?.getSettings().columns;
      return (
        hotInstance?.toPhysicalColumn(
          (columns as ColumnSettings[]).findIndex((c) => {
            return c.columnType === column;
          }),
        ) ?? -1
      );
    },
    [],
  );

  const getPhysicalRowIndex = useCallback(
    (hotInstance: Handsontable | null | undefined, row: number) => {
      return hotInstance?.toPhysicalRow(row) ?? -1;
    },
    [],
  );

  const calcTableTotal = useCallback(
    (data: Record<string, string>[]) => {
      return data.reduce((acc, row) => {
        const isLumpSum = isLumpSumUomText(getCellValue(row, COLUMN_TYPE.UOM));

        const quantity = Number(
          getCellValue(row, COLUMN_TYPE.Quantity) ||
            getCellValue(row, COLUMN_TYPE.PositiveQuantity),
        );
        const unitPrice = isLumpSum
          ? 1
          : Number(
              getCellValue(row, COLUMN_TYPE.UnitPrice) ||
                getCellValue(row, COLUMN_TYPE.PrefilledPrice),
            );
        return acc + calcExtPrice(quantity, unitPrice);
      }, 0);
    },
    [calcExtPrice, getCellValue],
  );

  const setCells = useCallback(
    (
      data: [number, number, string | number][],
      hotInstance: Handsontable | undefined | null,
      source: string = CUSTOM_SOURCE,
    ) => {
      hotInstance?.setDataAtCell(
        data.filter((d) => d[0] !== -1 && d[1] !== -1),
        source,
      );
    },
    [],
  );

  const toggleReadOnly = useCallback(
    (hotInstance: Handsontable | undefined | null, ignoreReadOnly: boolean) => {
      hotInstance?.updateSettings({
        cells: function () {
          return { ...this, ignoreReadOnly };
        },
      });
    },
    [],
  );

  const appendRows = useCallback(
    (
      dataToAppend: Record<string, string>[],
      hotInstance: Handsontable | undefined | null,
    ) => {
      let lastNonEmptyRow =
        hotInstance?.getData().findLastIndex((row) => !rowIsEmpty(row)) ?? -1;
      toggleReadOnly(hotInstance, true);
      const cellsToAdd = [] as [number, number, string | number][];
      hotInstance?.alter(
        "insert_row_below",
        lastNonEmptyRow === -1 ? 0 : lastNonEmptyRow,
        dataToAppend.length,
      );
      dataToAppend.forEach((row, index) => {
        const physicalRow = hotInstance?.toPhysicalRow(
          index + lastNonEmptyRow + 1,
        );
        Object.entries(row).forEach(([key, value]) => {
          const columnIndex = getPhysicalColumnIndex(
            hotInstance,
            key as COLUMN_TYPE,
          );
          if (columnIndex !== -1) {
            cellsToAdd.push([physicalRow || 0, columnIndex, value]);
          }
        });
      });
      setCells(cellsToAdd, hotInstance);
      hotInstance?.selectRows(
        lastNonEmptyRow + 1,
        lastNonEmptyRow + dataToAppend.length,
      );
      toggleReadOnly(hotInstance, false);
    },
    [getPhysicalColumnIndex, rowIsEmpty, setCells, toggleReadOnly],
  );

  const tableHasColumn = useCallback(
    (column: COLUMN_TYPE) => {
      return config.some((col) => col.columnType === column);
    },
    [config],
  );

  return {
    getCellValue,
    rowIsEmpty,
    getRowUomCreatableValue,
    getCostCodeId,
    getCellWithAdditionalData,
    confirmRemove,
    addMissingMaterials,
    addMissingManufacturers,
    addMissingZones,
    addMissingTags,
    getNameParts,
    getPrefilledValue,
    findMaterialByName,
    getFormattedMaterialName,
    sanitizeMaterialName,
    sanitizeValue,
    getPhysicalColumnIndex,
    getPhysicalRowIndex,
    isBuyoutItem,
    calcTableTotal,
    setCells,
    appendRows,
    tableHasColumn,
    toggleReadOnly,
  };
};
