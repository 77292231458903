export const useLocalStorage = () => {
  const readValue = <T>(key: string, defaultValue: T | null = null) => {
    const value = window.localStorage.getItem(key);
    if (value) {
      try {
        return JSON.parse(value) as T;
      } catch {
        return value;
      }
    }
    return defaultValue;
  };

  const setValue = <T>(key: string, value: T) => {
    if (value !== null && value !== undefined) {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(key);
    }
  };

  const removeValue = (key: string) => {
    window.localStorage.removeItem(key);
  };

  return {
    readValue,
    setValue,
    removeValue,
  };
};
