import { ReleaseItemIssueDescription } from "@/common/components/issue-details/ReleaseItemIssueDescription";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { Select } from "@/common/components/select/components/single/Select";
import { TextField } from "@/common/components/textfield/TextField";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { useIssueTypes } from "@/common/hooks/useIssueTypes";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AssetContext,
  AssetType,
  ProjectItemFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseIssueType,
  ReleaseItemFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReleaseUpdate } from "../../../../providers/ReleaseUpdateProvider";

const OverlayPanelContainer = tw.div`
  grid
`;
const Label = tw.div`
  font-medium text-base mb-2 mx-1 mt-2
`;

const Container = tw.div`
  bg-gray-100 -mx-7 p-5
`;

const ItemCont = tw.div`
  grid grid-cols-2 border-t border-b border-dashed p-2 my-4
`;

const TextFieldWithBackground = tw(TextField)<{ staticText?: boolean }>`
  w-full mb-3
  ${({ staticText }: { staticText?: boolean }) =>
    staticText ? "" : "bg-white"}
`;

const NumericalInputWithBackground = tw(QuantityPicker)`
  w-full bg-white h-7
`;

const IssueOptions = tw.div`
  grid grid-cols-2 gap-5
`;

type ReportIssueWithProviderProps = {
  onClose: () => void;
  item?: ExpandedReleaseItem;
  batch?: boolean;
};

const ReportIssueWithProvider: FC<ReportIssueWithProviderProps> = ({
  onClose,
  item,
  batch,
}) => {
  const { updateRelease } = useReleaseUpdate();

  const intl = useIntl();
  const { assets } = useUploadAssets();
  const { release } = useRelease();

  const existingIssue = useMemo(() => item?.issues?.[0], [item]);
  const [issue, setIssue] = useState<{
    description: string;
    photoUrls: string[];
    issueType?: ReleaseIssueType;
    quantityDecimal?: string;
  }>({
    description: existingIssue?.description || "",
    photoUrls: assets.map((asset) => asset.url) || [],
    issueType: existingIssue?.issueType,
    quantityDecimal: existingIssue?.quantityDecimal || "0",
  });

  const save = useCallback(() => {
    if (release && item && issue.issueType) {
      updateRelease(
        {
          releaseId: release.id,
          version: release.version,
          issues: [
            {
              ...issue,
              issueType: issue.issueType,
              photoUrls: assets.map((asset) => asset.url),
              releaseItemId: item.id,
            },
          ],
        },
        {
          batch,
        },
      );
    }

    onClose();
  }, [assets, batch, issue, item, onClose, release, updateRelease]);

  const issueTypes = useIssueTypes();

  if (
    existingIssue &&
    (release?.status === ReleaseStatus.Received ||
      release?.status === ReleaseStatus.PartiallyReceived)
  ) {
    return (
      <OverlayPanel title={intl.$t({ id: "REPORT_ISSUE" })} onCancel={onClose}>
        <OverlayPanelContainer>
          <ReleaseItemIssueDescription
            item={
              {
                ...item,
              } as ReleaseItemFieldsFragment
            }
          />
        </OverlayPanelContainer>
      </OverlayPanel>
    );
  }

  return (
    <OverlayPanel
      title={intl.$t({ id: "REPORT_ISSUE" })}
      onSave={save}
      onCancel={onClose}
      disableSave={
        !issue.issueType ||
        !issue.quantityDecimal ||
        (issue.issueType !== ReleaseIssueType.MoreThanOrdered &&
          new Decimal(issue.quantityDecimal).greaterThan(
            item?.quantityDecimal || 0,
          ))
      }
    >
      <OverlayPanelContainer>
        <FormattedMessage id="ITEM" />
        <ItemCont>
          <ProjectItemMaterialView
            item={item as ProjectItemFieldsFragment}
            includeCounter={false}
          />
          <ValueUnit value={item?.quantityDecimal || 0} uom={item?.uom} />
        </ItemCont>
        <Container>
          <IssueOptions>
            <Select
              options={issueTypes}
              value={issue.issueType}
              required
              onChange={(value) =>
                setIssue({ ...issue, issueType: value as ReleaseIssueType })
              }
              getLabel={(option) => option.label}
              getValue={(option) => option.value}
              className="min-w-32"
              placeholder={intl.$t({ id: "SELECT_ISSUE_TYPE" })}
              testId="issue-type-selector"
            />
            <NumericalInputWithBackground
              id="quantity-affected"
              label={
                <Tooltip
                  id="quantity-affected"
                  element={
                    <>
                      <FormattedMessage
                        id="QUANTITY_AFFECTED"
                        values={{
                          unit:
                            item?.uom?.mnemonic || item?.uom?.pluralDescription,
                        }}
                      />{" "}
                      *
                    </>
                  }
                >
                  {item?.estimateUom.pluralDescription}
                </Tooltip>
              }
              quantity={issue.quantityDecimal}
              error={
                issue.issueType !== ReleaseIssueType.MoreThanOrdered &&
                new Decimal(issue.quantityDecimal || 0).greaterThan(
                  item?.quantityDecimal || 0,
                )
              }
              saveQuantity={(value: string | null) => {
                debugger;
                setIssue({
                  ...issue,
                  quantityDecimal: value || "0",
                });
              }}
              testId="quantity-affected-input"
            />
          </IssueOptions>
          <Label>
            <FormattedMessage
              id="ISSUE_DESCRIPTION"
              values={{ bold: (...chunks) => <b>{chunks}</b> }}
            />
          </Label>
          <TextFieldWithBackground
            multiline
            placeholder={intl.$t({
              id: "DESCRIBE_ISSUE",
            })}
            rows={5}
            value={issue?.description}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setIssue({
                ...issue,
                description: ev.target.value,
              })
            }
          />
          <Label>
            <FormattedMessage id="PHOTOS_OF_ISSUE_TO_REPORT" />
          </Label>
          <UploadAsset testId="photos-of-issues-uploader" />
        </Container>
      </OverlayPanelContainer>
    </OverlayPanel>
  );
};

type Props = {
  item?: ExpandedReleaseItem;
  onClose: () => void;
  batch?: boolean;
  release?: ReleaseFieldsFragment | null;
};

export const ReportIssue: FC<Props> = ({ onClose, item, batch }) => {
  const { release } = useRelease();

  const issueAssets = useMemo(() => {
    return item?.issues?.[0]?.photos ?? [];
  }, [item?.issues]);

  return (
    <UploadAssetProvider
      initialAssets={issueAssets}
      context={AssetContext.DeliveryIssue}
      projectId={release?.project?.id ?? ""}
      type={AssetType.Image}
    >
      <ReportIssueWithProvider onClose={onClose} item={item} batch={batch} />
    </UploadAssetProvider>
  );
};
