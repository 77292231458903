import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ReleaseStatus } from "@/generated/graphql";
import { Menu, MenuItem } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaChevronDown } from "react-icons/fa";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceImportExternalPO } from "../../../../providers/InvoiceImportExternalPoProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";
const MenuButton = tw(
  PrimaryButton,
)`w-6 h-6 min-w-6 max-h-6 rounded-xl cursor-pointer h-full items-center justify-center mr-2`;
const Row = tw.div`flex flex-row items-center justify-center`;
const Header = tw(Row)`justify-between mb-4`;
const ChevronDownStyled = tw(FaChevronDown)`text-base mt-0.5`;
const Title = tw.div`text-base font-medium ml-1 mt-0.5`;

export const InvoiceSelectModeHeader = ({ className = "" }) => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { setFooterState, footerState, invoice } = useInvoiceVerification();
  const { setValue } = useFormContext<InvoiceCreateReleaseFormValues>();

  const {
    setMatchedOrderViewState,
    matchedOrderViewState,
    setPreselectedPoNumber,
  } = useInvoiceMatchedOrder();
  const {
    importModalOpened,
    openModal,
    importedItems,
    closeModal,
    resetImportedData,
  } = useInvoiceImportExternalPO();
  const { includePoNumbering } = usePoNumberingSettingsCheck();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const options = useMemo(() => {
    const buttons = [
      {
        label: intl.$t({ id: "INVOICE_ORDER_SELECT_INFO" }),
        onClick: () => {
          setPreselectedPoNumber("");
          setFooterState(InvoiceFooterState.MATCH_ORDER);
          setMatchedOrderViewState(MatchedOrderViewState.MATCH_ORDER);
          handleClose();
        },
        selected:
          footerState === InvoiceFooterState.MATCH_ORDER &&
          matchedOrderViewState === MatchedOrderViewState.MATCH_ORDER,
      },
      {
        label: intl.$t({ id: "CREATE_NEW_ORDER_FROM_INVOICE" }),
        onClick: () => {
          closeModal();
          resetImportedData();
          if (invoice?.poNumber && includePoNumbering) {
            setValue("poNumber", invoice.poNumber);
          }
          setValue("vendorId", "");
          setFooterState(InvoiceFooterState.CREATE_ORDER);
          setMatchedOrderViewState(MatchedOrderViewState.CREATE_ORDER);
          handleClose();
        },
        selected:
          footerState === InvoiceFooterState.CREATE_ORDER &&
          matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER &&
          !importedItems,
      },
    ];
    if (connectedSourceSystem) {
      buttons.push({
        label: intl.$t(
          { id: "IMPORT_PO_FROM_SYSTEM_AS_NEW_ORDER" },
          {
            system: connectedSourceSystem
              ? intl.$t({ id: `SOURCE_SYSTEM_${connectedSourceSystem}` })
              : "",
          },
        ),
        onClick: () => {
          handleClose();
          openModal({ skipPoList: false });
        },
        selected:
          footerState === InvoiceFooterState.CREATE_ORDER &&
          matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER &&
          !!importedItems,
      });
    }
    return buttons;
  }, [
    intl,
    footerState,
    matchedOrderViewState,
    importedItems,
    connectedSourceSystem,
    setPreselectedPoNumber,
    setFooterState,
    setMatchedOrderViewState,
    handleClose,
    closeModal,
    resetImportedData,
    invoice?.poNumber,
    includePoNumbering,
    setValue,
    openModal,
  ]);

  return (
    <Header className={className}>
      <Row>
        <MenuButton onClick={handleClick}>
          <ChevronDownStyled />
        </MenuButton>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          variant="selectedMenu"
          onClose={handleClose}
          keepMounted
        >
          {options.map((item, index) => (
            <MenuItem
              key={index}
              onClick={item.onClick}
              selected={item.selected}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
        <Title>{options.find((o) => o.selected)?.label}</Title>
        <If
          isTrue={
            footerState === InvoiceFooterState.CREATE_ORDER &&
            matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER &&
            !importModalOpened
          }
        >
          <ReleaseStatusChip
            className="ml-2 bg-gray-100"
            status={ReleaseStatus.Draft}
            type="breadcrumb"
            releaseType={undefined}
          />
        </If>
      </Row>
    </Header>
  );
};
