import { QUERYSTRING } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  CreateProjectInput,
  ProjectProjectsFieldsFragment,
  ProjectStatus,
  QueryProjectsFilter,
  namedOperations,
  useCreateProjectMutation,
  useDeleteProjectMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionUndefinedPromise } from "@/types/NoFunction";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { useProjectsWithPagination } from "../hooks/useProjectsWithPagination";

type ProjectsProviderContextType = {
  projects: ProjectProjectsFieldsFragment[];
  createProject: (
    projectInput: CreateProjectInput,
  ) => Promise<{ id: string; name: string } | undefined>;
  deleteProject: (id: string) => Promise<void>;
  getActiveProjects: (locationId: string) => void;
  loading: boolean;
  totalCount: number;
  filter?: QueryProjectsFilter;
  setFilter: (filter: QueryProjectsFilter) => void;
  isFiltered: boolean;
};

const ProjectsProviderContext = createContext<ProjectsProviderContextType>({
  projects: [],
  createProject: NoFunctionUndefinedPromise,
  deleteProject: NoFunctionUndefinedPromise,
  getActiveProjects: NoFunction,
  loading: false,
  totalCount: 0,
  filter: undefined,
  setFilter: NoFunction,
  isFiltered: false,
});

export const ProjectsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { queryParams } = useQueryParams();
  const [filter, setFilter] = useState<QueryProjectsFilter>({
    name: queryParams.get(QUERYSTRING.SEARCH),
    deleted: false,
    statuses:
      queryParams.getAll(QUERYSTRING.STATUSES).length &&
      queryParams.getAll(QUERYSTRING.STATUSES)[0].length
        ? ((queryParams.getAll(QUERYSTRING.STATUSES)?.[0] || "").split(
            ",",
          ) as ProjectStatus[])
        : [ProjectStatus.Active, ProjectStatus.Awarded],
  });
  const {
    projects,
    loading: queryLoader,
    error,
    totalCount,
    setPage,
  } = useProjectsWithPagination(filter);
  const [createNewProject, { loading: createProjectLoader }] =
    useCreateProjectMutation();
  const [deleteExistingProject] = useDeleteProjectMutation();
  const { setError } = useGlobalError();

  const loading = useMemo(
    () => queryLoader || createProjectLoader,
    [queryLoader, createProjectLoader],
  );

  useErrorEffect(error);

  const getActiveProjects = (orgLocationID: string) => {
    if (orgLocationID !== filter?.orgLocationID) {
      setFilter({
        orgLocationID,
        statuses: [ProjectStatus.Awarded, ProjectStatus.Active],
      });
    }
  };

  const setFilterUpdate = (filter: QueryProjectsFilter) => {
    setPage({
      page: 0,
      queryParams: {
        ...filter,
      },
    });
    setFilter(filter);
  };

  const createProject = async (projectInput: CreateProjectInput) => {
    const {
      locationId,
      name,
      address,
      jobNumber,
      status,
      startDate,
      budget,
      team,
      tags,
      zones,
      taxExempt,
    } = projectInput;
    if (locationId && name) {
      try {
        const newAddress = {
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          country: address.country,
          postalCode: address.postalCode,
          state: address.state,
        };
        const { data: newProject, errors } = await createNewProject({
          variables: {
            input: {
              name,
              locationId,
              address: newAddress,
              jobNumber,
              startDate,
              status,
              budget,
              team,
              tags,
              zones,
              taxExempt,
            },
          },
          update: (cache) => {
            cleanQuery(cache, namedOperations.Query.Projects);
          },
        });
        setError(errors);
        if (newProject?.createNewProject) {
          return newProject?.createNewProject;
        }
      } catch (errors) {
        setError(errors);
      }
    }
  };

  const deleteProject = async (id: string) => {
    try {
      const { errors } = await deleteExistingProject({
        variables: { id },
        update: (cache) => {
          cleanQuery(cache, namedOperations.Query.Projects);
        },
      });
      setError(errors);
    } catch (errors) {
      setError(errors);
    }
  };

  return (
    <ProjectsProviderContext.Provider
      value={{
        filter,
        isFiltered: !!(filter?.name || filter?.statuses?.length),
        setFilter: setFilterUpdate,
        projects: projects || [],
        createProject,
        getActiveProjects,
        deleteProject,
        loading,
        totalCount,
      }}
    >
      {children}
    </ProjectsProviderContext.Provider>
  );
};

export const useProjects = (): ProjectsProviderContextType =>
  useContext(ProjectsProviderContext);
