import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  NestedWizardModalPage,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { DEFAULT_ITEMS_PER_PAGE, LUMP_SUM_UOM } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { getUTCDate } from "@/common/utils/dates/getUTCDate";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  ExternalPOItem,
  ExternalPOTableCategoryId,
} from "@/contractor/pages/home/common/external-po/ExternalPOItemsTable.configuration";
import * as Types from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import Decimal from "decimal.js";
import * as React from "react";
import { UseFormReturn, useForm, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDebounce } from "use-debounce";
import {
  ExternalPOsPaginatedOutput,
  useExternalPOsWithPagination,
} from "../../../../../../../common/hooks/useExternalPOsWithPagination";
import { InvoiceExternalPOItems } from "../components/matched-order/components/invoice-import-external-po/invoice-external-po-items/InvoiceExternalPOItems";
import { InvoiceExternalPOs } from "../components/matched-order/components/invoice-import-external-po/invoice-external-pos/InvoiceExternalPOs";
import { InvoiceCreateReleaseFormValues } from "../components/matched-order/components/InvoiceVerificationForm";
import { useInvoicePermissions } from "../hooks/useInvoicePermissions";
import { InvoiceImportedExternalPOValues } from "./enums/InvoiceImportedExternalPOValues";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "./InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "./InvoiceVerificationProvider";

type ProviderContextType = {
  importModalOpened: boolean;
  openModal: ({
    skipPoList,
    hasMissingPoLink,
  }: {
    skipPoList: boolean;
    hasMissingPoLink?: boolean;
  }) => void;
  closeModal: () => void;
  resetImportedData: () => void;
  modalPages: WizardModalPage[];
  externalPOsQueryForm:
    | UseFormReturn<
        Pick<
          Types.ExternalPOsQueryInput,
          "projectId" | "sellerOrgLocationID" | "search" | "minDate" | "maxDate"
        >,
        unknown
      >
    | undefined;
  externalPOsPaginatedOutput: ExternalPOsPaginatedOutput;
  selectedBaseExternalPO: Types.ExternalPoBaseFieldsFragment | undefined;
  setSelectedBaseExternalPO: (
    baseExternalPO: Types.ExternalPoBaseFieldsFragment | undefined,
  ) => void;
  externalPo: Types.ExternalPoFieldsFragment | null | undefined;
  loadingExternalPo: boolean;
  includedItemsCategory: CategoryState<ExternalPOItem>[];
  missingTagsCategory: CategoryState<ExternalPOItem>[];
  missingCostCodesCategory: CategoryState<ExternalPOItem>[];
  unsupportedCostTypesCategory: CategoryState<ExternalPOItem>[];
  aggregatedItems: Types.ExternalPoItemFieldsFragment[] | undefined;
  importExternalCostCodes: () => void;
  importingExternalCostCodes: boolean;
  selectedSellerOrgLocationId: string | null;
  setSelectedSellerOrgLocationId: (id: string | null) => void;
  importedPoExternalId: string | null;
  importedPoItemized: boolean | null;
  importedItems: null | Types.InvoiceItemFieldsFragment[];
  importedExternalPOValues: InvoiceImportedExternalPOValues[];
  orderTypeId?: string | null;
  setOrderTypeId?: (id: string | null) => void;
  hasMissingPoLink: boolean;
};

const ProviderContext = React.createContext<ProviderContextType>({
  importModalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,
  resetImportedData: NoFunction,
  modalPages: [],
  externalPOsQueryForm: undefined,
  externalPOsPaginatedOutput: {
    externalPOs: [],
    loadingExternalPOs: false,
    page: 1,
    count: 0,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    hasPrevious: false,
    hasNext: false,
    nextPage: NoFunction,
    previousPage: NoFunction,
    setPageFn: NoFunction,
    setPageSizeFn: NoFunction,
  },
  selectedBaseExternalPO: undefined,
  setSelectedBaseExternalPO: NoFunction,
  externalPo: null,
  loadingExternalPo: false,
  includedItemsCategory: [],
  missingTagsCategory: [],
  missingCostCodesCategory: [],
  unsupportedCostTypesCategory: [],
  aggregatedItems: [],
  importExternalCostCodes: NoFunction,
  importingExternalCostCodes: false,
  selectedSellerOrgLocationId: null,
  setSelectedSellerOrgLocationId: NoFunction,
  importedExternalPOValues: [],
  importedPoExternalId: null,
  importedPoItemized: null,
  importedItems: null,
  orderTypeId: null,
  setOrderTypeId: NoFunction,
  hasMissingPoLink: false,
});

export const InvoiceImportExternalPOProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { setSuccessAlert } = useSnackbar();
  const { connectedSourceSystem } = useOrgSettings();
  const { setFooterState, invoice, shouldFetchExternalPO } =
    useInvoiceVerification();
  const { setValue, watch } = useFormContext<InvoiceCreateReleaseFormValues>();

  const { moveToNextStep, setStep } = useNestedStepper();
  const { setMatchedOrderViewState } = useInvoiceMatchedOrder();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const [importModalOpened, setImportModalOpened] = React.useState(false);
  const [hasMissingPoLink, setHasMissingPoLink] = React.useState(false);
  const [selectedBaseExternalPO, setSelectedBaseExternalPO] = React.useState<
    Types.ExternalPoBaseFieldsFragment | undefined
  >();
  const [selectedSellerOrgLocationId, setSelectedSellerOrgLocationId] =
    React.useState<string | null>(null);
  const [importedItems, setImportedItems] = React.useState<
    Types.InvoiceItemFieldsFragment[] | null
  >(null);
  const [orderTypeId, setOrderTypeId] = React.useState<string | null>(null);
  const [importedPoExternalId, setImportedPoExternalId] = React.useState<
    string | null
  >(null);
  const [importedPoItemized, setImportedPoItemized] = React.useState<
    boolean | null
  >(null);
  const [importedExternalPOValues, setImportedExternalPOValues] =
    React.useState<InvoiceImportedExternalPOValues[]>([]);
  const { defaultOrderType } = useOrderTypeOptions();
  const { getUomByName } = useUomOptions();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { openDialog } = useDialog();

  React.useEffect(() => {
    if (defaultOrderType && !orderTypeId) {
      setOrderTypeId(defaultOrderType.id);
    }
  }, [defaultOrderType, orderTypeId]);

  const externalPOsQueryForm = useForm<
    Pick<
      Types.ExternalPOsQueryInput,
      "projectId" | "sellerOrgLocationID" | "search" | "minDate" | "maxDate"
    >
  >({
    defaultValues: {
      projectId: undefined,
      sellerOrgLocationID: undefined,
      search: undefined,
      minDate: undefined,
      maxDate: undefined,
    },
    mode: "onChange",
  });
  const projectId = externalPOsQueryForm.watch("projectId");
  const sellerOrgLocationID = externalPOsQueryForm.watch("sellerOrgLocationID");
  const search = externalPOsQueryForm.watch("search");
  const minDate = externalPOsQueryForm.watch("minDate");
  const maxDate = externalPOsQueryForm.watch("maxDate");
  const { fetchInvoicePermissions } = useInvoicePermissions();
  const externalPOsPaginatedOutput = useExternalPOsWithPagination({
    sourceSystem: connectedSourceSystem,
    projectId: !!projectId && projectId !== "" ? projectId : undefined,
    sellerOrgLocationID:
      !!sellerOrgLocationID && sellerOrgLocationID !== ""
        ? sellerOrgLocationID
        : undefined,
    search: search ?? undefined,
    minDate: minDate ? Number(minDate) : undefined,
    maxDate: maxDate ? Number(maxDate) : undefined,
    skip: !shouldFetchExternalPO || !includePoNumbering,
  });
  const invoiceCreateReleaseFormPoNumber = watch("poNumber");
  const invoiceCreateReleaseFormProjectId = watch("projectId");
  const [debouncedInvoiceCreateReleaseFormPoNumber] = useDebounce(
    invoiceCreateReleaseFormPoNumber,
    800,
  );

  const externalPoQueryOptions = React.useMemo(() => {
    if (selectedBaseExternalPO) {
      return {
        variables: {
          input: {
            sourceSystem:
              connectedSourceSystem ?? Types.SourceSystem.Foundation,
            externalId: selectedBaseExternalPO.externalId,
            projectId: hasFeatureInConnectedSourceSystem(
              IntegrationFeature.ExternalPoReadsProjectSpecific,
            )
              ? selectedBaseExternalPO.project?.project?.id
              : undefined,
            releaseTypeId: orderTypeId,
          },
        },
        skip:
          !shouldFetchExternalPO ||
          !connectedSourceSystem ||
          !selectedBaseExternalPO.externalId ||
          (hasFeatureInConnectedSourceSystem(
            IntegrationFeature.ExternalPoReadsProjectSpecific,
          )
            ? !selectedBaseExternalPO.project?.project?.id
            : false),
      };
    }
    if (!!invoice?.release && shouldFetchExternalPO) {
      return {
        variables: {
          input: {
            sourceSystem:
              connectedSourceSystem ?? Types.SourceSystem.Foundation,
            number: invoice?.release?.poNumber,
            projectId:
              !!connectedSourceSystem &&
              hasFeatureInConnectedSourceSystem(
                IntegrationFeature.ExternalPoReadsProjectSpecific,
              )
                ? invoice?.release?.project?.id
                : undefined,
          },
        },
        skip:
          !connectedSourceSystem ||
          !invoice?.release?.poNumber ||
          (hasFeatureInConnectedSourceSystem(
            IntegrationFeature.ExternalPoReadsProjectSpecific,
          )
            ? !invoice?.release?.project?.id
            : false),
      };
    }
    return {
      variables: {
        input: {
          sourceSystem: connectedSourceSystem ?? Types.SourceSystem.Foundation,
          number: debouncedInvoiceCreateReleaseFormPoNumber,
          projectId:
            !!connectedSourceSystem &&
            hasFeatureInConnectedSourceSystem(
              IntegrationFeature.ExternalPoReadsProjectSpecific,
            )
              ? invoiceCreateReleaseFormProjectId
              : undefined,
        },
      },
      skip:
        !shouldFetchExternalPO ||
        !connectedSourceSystem ||
        !debouncedInvoiceCreateReleaseFormPoNumber ||
        (hasFeatureInConnectedSourceSystem(
          IntegrationFeature.ExternalPoReadsProjectSpecific,
        )
          ? !invoiceCreateReleaseFormProjectId
          : false),
    };
  }, [
    selectedBaseExternalPO,
    invoice?.release,
    shouldFetchExternalPO,
    connectedSourceSystem,
    debouncedInvoiceCreateReleaseFormPoNumber,
    invoiceCreateReleaseFormProjectId,
    hasFeatureInConnectedSourceSystem,
    orderTypeId,
  ]);

  const {
    data,
    loading: loadingExternalPo,
    error,
    refetch,
  } = Types.useExternalPoQuery({
    ...externalPoQueryOptions,
    nextFetchPolicy: "network-only",
  });

  useErrorEffect(error);

  React.useEffect(() => {
    if (
      data?.externalPO?.vendor?.orgPreferredVendors?.[0]?.sellerOrgLocation.id
    ) {
      setSelectedSellerOrgLocationId(
        data?.externalPO?.vendor?.orgPreferredVendors?.[0]?.sellerOrgLocation
          .id,
      );
    }
  }, [data]);

  const [importCostCodesMutation, { loading: importingExternalCostCodes }] =
    Types.useImportCostCodesMutation();
  const importExternalCostCodes = React.useCallback(async () => {
    try {
      if (
        (data?.externalPO?.itemGroups.missingCostCode ?? []).length > 0 &&
        connectedSourceSystem
      ) {
        const externalCostCodeIds = (
          data?.externalPO?.itemGroups.missingCostCode ?? []
        )
          .filter((c) => !!c.externalCostCode?.externalId)
          .map((c) => c.externalCostCode?.externalId);

        await importCostCodesMutation({
          variables: {
            input: {
              sourceSystem: connectedSourceSystem,
              externalCostCodeIds: externalCostCodeIds as string[],
            },
          },
          refetchQueries: [{ query: Types.ViewerCostCodesDocument }],
        });

        await refetch();
      }
    } catch (error) {
      setError(error);
    }
  }, [
    data?.externalPO,
    connectedSourceSystem,
    refetch,
    setError,
    importCostCodesMutation,
  ]);

  const missingTagsCategory = React.useMemo(
    () =>
      (data?.externalPO?.itemGroups.missingTag ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.MISSING_TAG,
              isOpened: true,
              name: intl.$t({ id: "MISSING_PHASE_CODES" }),
              description: intl.$t({ id: "MISSING_PHASE_CODES_TOOLTIP" }),
              items: (data?.externalPO?.itemGroups.missingTag ?? []).map(
                (i) => ({
                  ...i,
                  id: i.externalId,
                }),
              ),
            },
          ]
        : [],
    [data?.externalPO?.itemGroups.missingTag, intl],
  );
  const missingCostCodesCategory = React.useMemo(
    () =>
      (data?.externalPO?.itemGroups.missingCostCode ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.MISSING_COST_CODES,
              isOpened: true,
              name: intl.$t({ id: "MISSING_COST_CODES" }),
              description: intl.$t({ id: "MISSING_COST_CODES_TOOLTIP" }),
              items: (data?.externalPO?.itemGroups.missingCostCode ?? []).map(
                (i) => ({ ...i, id: i.externalId }),
              ),
            },
          ]
        : [],
    [data?.externalPO?.itemGroups.missingCostCode, intl],
  );
  const unsupportedCostTypesCategory = React.useMemo(
    () =>
      (data?.externalPO?.itemGroups.unsupportedCostType ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.UNSUPPORTED_COST_TYPES,
              isOpened: true,
              name: intl.$t({ id: "UNSUPPORTED_COST_TYPES" }),
              description: intl.$t({ id: "UNSUPPORTED_COST_TYPES_TOOLTIP" }),
              items: (
                data?.externalPO?.itemGroups.unsupportedCostType ?? []
              ).map((i) => ({ ...i, id: i.externalId })),
            },
          ]
        : [],
    [data?.externalPO?.itemGroups.unsupportedCostType, intl],
  );
  const includedItemsCategory = React.useMemo(
    () => [
      {
        id: ExternalPOTableCategoryId.INCLUDED,
        isOpened: true,
        name: "",
        items: [
          ...(data?.externalPO?.itemGroups.importable ?? []),
          ...(data?.externalPO?.itemGroups.additionalCharges ?? []),
          ...(data?.externalPO?.itemGroups.salesTax ?? []),
        ].map((i) => ({ ...i, id: i.externalId })),
      },
    ],
    [data?.externalPO?.itemGroups],
  );
  const aggregatedItems = React.useMemo(
    () => data?.externalPO?.itemGroups.aggregatedItems,
    [data?.externalPO?.itemGroups],
  );
  const isNonItemizedPO = React.useMemo(
    () => (data?.externalPO?.itemGroups.aggregatedItems ?? []).length > 0,
    [data?.externalPO],
  );

  const importExternalPO = React.useCallback(() => {
    if (data?.externalPO) {
      const importedValues: InvoiceImportedExternalPOValues[] = [];

      if (!isNonItemizedPO) {
        setImportedItems(
          (data.externalPO.itemGroups.importable ?? []).map((i) => {
            const isLumpSum = getUomByName(i.uom)?.mnemonic === LUMP_SUM_UOM;
            return {
              id: i.externalId,
              description: i.description,
              costCode: i.costCode?.description ?? i.wbsTag?.name,
              UOM: i.uom,
              quantityDecimal: isLumpSum ? "1" : i.quantity,
              unitPrice: isLumpSum ? i.amount : i.unitCost,
              extPrice: isLumpSum
                ? i.amount
                : new Decimal(i?.unitCost).mul(i?.quantity).toNumber(),
            };
          }),
        );
        if (data.externalPO.itemGroups.additionalCharges) {
          importedValues.push(
            InvoiceImportedExternalPOValues.ADDITIONAL_CHARGES,
          );
          setValue(
            "additionalCharges",
            data.externalPO.itemGroups.additionalCharges.map((i) => ({
              amount: i.amount,
              description: i.description,
              id: i.externalId,
            })),
          );
        }
        if (data.externalPO.itemGroups.salesTax) {
          importedValues.push(InvoiceImportedExternalPOValues.SALES_TAX);
          setValue(
            "customTaxAmount",
            data.externalPO.itemGroups.salesTax
              .reduce(
                (acc, next) =>
                  new Decimal(acc).add(new Decimal(next.amount)).toNumber(),
                0,
              )
              .toString(),
          );
        }
      }
      if (selectedSellerOrgLocationId) {
        importedValues.push(InvoiceImportedExternalPOValues.VENDOR);
        setValue("vendorId", selectedSellerOrgLocationId);
      }
      if (data.externalPO?.project?.project) {
        setValue(
          "businessLocationId",
          data.externalPO.project.project.location.id,
        );
        setValue("projectId", data.externalPO.project.project.id);
        importedValues.push(InvoiceImportedExternalPOValues.PROJECT);
      }
      if (data.externalPO?.date) {
        setValue(
          "orderDate",
          defaultReleaseDate(getUTCDate(data.externalPO.date)),
        );
      }
      if (orderTypeId) {
        setValue("orderTypeId", orderTypeId);
      }
      setValue("poNumber", data.externalPO.number);
      importedValues.push(InvoiceImportedExternalPOValues.PO_NUMBER);
      setImportedPoExternalId(data?.externalPO?.externalId);
      setImportedPoItemized(!isNonItemizedPO);
      setImportedExternalPOValues(importedValues);
      setFooterState(InvoiceFooterState.CREATE_ORDER);
      setMatchedOrderViewState(MatchedOrderViewState.CREATE_ORDER);
      setImportModalOpened(false);
      setSuccessAlert(
        intl.$t({
          id: isNonItemizedPO
            ? "NON_ITEMIZED_EXTERNAL_PO_ASSOCIATED"
            : "EXTERNAL_PO_IMPORTED",
        }),
      );
    }
  }, [
    data?.externalPO,
    isNonItemizedPO,
    selectedSellerOrgLocationId,
    orderTypeId,
    setValue,
    setFooterState,
    setMatchedOrderViewState,
    setSuccessAlert,
    intl,
    getUomByName,
  ]);

  const importExternalPOWithConfirmation = React.useCallback(async () => {
    if (!invoice || !selectedBaseExternalPO?.project?.project?.id) {
      return;
    }
    const { data: invoicePermissionData } = await fetchInvoicePermissions(
      invoice?.id,
      selectedBaseExternalPO?.project?.project?.id,
    );
    if (
      invoicePermissionData?.invoice?.permissions.approve ===
      Types.AuthorizationStatus.Authorized
    ) {
      importExternalPO();
    } else {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "IMPORT_ORDER" }),
        icon: <WarningIcon />,
        titleClassName: "w-96",
        title: intl.$t({
          id: "IMPORT_ORDER",
        }),
        text: intl.$t({
          id: "APPROVER_NOTIFICATION_NOTE",
        }),
        handleConfirm: importExternalPO,
      });
    }
  }, [
    invoice,
    selectedBaseExternalPO?.project?.project?.id,
    fetchInvoicePermissions,
    importExternalPO,
    openDialog,
    intl,
  ]);

  const openModal = React.useCallback(
    ({
      skipPoList,
      hasMissingPoLink,
    }: {
      skipPoList: boolean;
      hasMissingPoLink?: boolean;
    }) => {
      setStep(skipPoList ? 1 : 0);
      setImportModalOpened(true);
      setHasMissingPoLink(!!hasMissingPoLink);
    },
    [setStep],
  );

  const resetImportedData = React.useCallback(() => {
    setSelectedSellerOrgLocationId(null);
    setImportedPoExternalId(null);
    setImportedPoItemized(null);
    setImportedExternalPOValues([]);
    setImportedItems(null);
    setOrderTypeId(null);
    externalPOsQueryForm.reset();
  }, [externalPOsQueryForm]);

  const closeModal = React.useCallback(() => {
    setImportModalOpened(false);
    setSelectedBaseExternalPO(undefined);
  }, []);

  const pages: WizardModalPage[] = React.useMemo(
    () => [
      {
        title: null,
        pages: [
          {
            hideHeader: true,
            component: <InvoiceExternalPOs />,
            onNextClick: () => {
              moveToNextStep();
            },
            onNextClickDisabled: !selectedBaseExternalPO,
            onCloseClick: closeModal,
          } as NestedWizardModalPage,
        ],
      },
      {
        title: null,
        pages: [
          {
            hideHeader: true,
            component: <InvoiceExternalPOItems />,
            onNextClick:
              loadingExternalPo ||
              ((data?.externalPO?.poLinks ?? []).length > 0 && !isNonItemizedPO)
                ? undefined
                : () => importExternalPOWithConfirmation(),
            onNextClickDisabled:
              (isNonItemizedPO
                ? false
                : includedItemsCategory[0].items.length === 0) ||
              !data?.externalPO?.project?.project ||
              (data?.externalPO?.vendor?.orgPreferredVendors ?? []).length ===
                0 ||
              ((data?.externalPO?.poLinks ?? []).length > 0 &&
                !isNonItemizedPO),
            onNextLabel: intl.$t({
              id: isNonItemizedPO ? "ASSOCIATE_PO_WITH_ORDER" : "IMPORT",
            }),
            onNextClassName: isNonItemizedPO ? "w-60" : "",
            onCloseClick: closeModal,
          } as NestedWizardModalPage,
        ],
      },
    ],
    [
      selectedBaseExternalPO,
      closeModal,
      loadingExternalPo,
      data?.externalPO?.poLinks,
      data?.externalPO?.project?.project,
      data?.externalPO?.vendor?.orgPreferredVendors,
      isNonItemizedPO,
      includedItemsCategory,
      intl,
      moveToNextStep,
      importExternalPOWithConfirmation,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        importModalOpened,
        openModal,
        closeModal,
        resetImportedData,
        modalPages: pages,
        externalPOsQueryForm,
        externalPOsPaginatedOutput,
        selectedBaseExternalPO,
        setSelectedBaseExternalPO,
        externalPo: data?.externalPO,
        loadingExternalPo,
        includedItemsCategory,
        missingTagsCategory,
        missingCostCodesCategory,
        unsupportedCostTypesCategory,
        aggregatedItems,
        importExternalCostCodes,
        importingExternalCostCodes,
        selectedSellerOrgLocationId,
        setSelectedSellerOrgLocationId,
        importedExternalPOValues,
        importedPoExternalId,
        importedPoItemized,
        importedItems,
        orderTypeId,
        setOrderTypeId,
        hasMissingPoLink,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceImportExternalPO = (): ProviderContextType =>
  React.useContext(ProviderContext);
